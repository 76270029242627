import { AccountingTypes } from '@/pages/Accounting/models/accounting.model';
import { AccountingService } from '@/shared/services/accounting/accounting.service';
import { DATE_API_FORMAT } from '@/utils/date.util';
import { ToastHelper } from '@/utils/toast.util';
import { uuid } from '@/utils/utils';
import moment from 'moment';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { DATE_VIEW_FULL_FORMAT } from './../../../utils/date.util';
import { ExcelData } from './accountig.model';

@Module({
  namespaced: true,
  name: 'accounting'
})
export default class AccountingModule extends VuexModule {
  loading = false;
  accountingData = [];
  accountingType = '';
  excelData: ExcelData | any = {};
  filters = null;
  meta: any = null;

  @Mutation
  public SET_LOADING_STATUS(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public SET_FILTERS(filters: string) {
    this.filters = filters;
  }

  @Mutation
  public SET_ACCOUNTIG_DATA(payload) {
    this.accountingData = payload ? [...payload] : [];
  }

  @Mutation
  public SET_ACCOUNTING_TYPE(payload) {
    this.accountingType = payload;
  }

  @Mutation
  public SET_EXCEL_DATA(payload) {
    this.excelData = payload ? { ...payload } : {};
  }

  @Mutation
  public SET_META_INFO(meta: any) {
    this.meta = meta;
  }

  @Action
  async search(payload: { acctType: AccountingTypes }) {
    const { acctType } = { ...payload };
    // this.context.commit('SET_DISPATCH_TYPE', acctType);
    // this.context.commit('SET_CATEGORY', acctType);
    this.context.commit('SET_LOADING_STATUS', true);
    let data;
    try {
      const res = await AccountingService.searchRequest(acctType, this.filters);

      data = res.data.data.map(i => ({
        ...i,
        ck: false,
        expirationDate: i.expirationDate
          ? moment(i.expirationDate, DATE_API_FORMAT).format(
              DATE_VIEW_FULL_FORMAT
            )
          : ''
      }));

      this.context.commit('SET_ACCOUNTIG_DATA', data);
      this.context.commit('SET_META_INFO', res.data.meta);
    } catch (err) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Accounting Error', err);
      ToastHelper.show('Accounting', err.response.data?.error, 8000, 'danger');
    }

    this.context.commit('SET_LOADING_STATUS', false);
    return data;
  }

  @Action
  async excel(acctType: AccountingTypes) {
    this.context.commit('SET_LOADING_STATUS', true);

    try {
      const res = await AccountingService.getExcel(acctType, this.filters);
      this.context.commit('SET_EXCEL_DATA', res.data);
    } catch (e) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Accounting excel Error', e);
      ToastHelper.show('Accounting excel', e.response.data?.error);
    }
    this.context.commit('SET_LOADING_STATUS', false);
  }

  @Action
  async setFilters(filters) {
    this.context.commit('SET_FILTERS', filters);
  }

  @Action
  async matchQB(data) {
    this.context.commit('SET_LOADING_STATUS', true);
    try {
      await AccountingService.matchQB(data);
    } catch (e) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('Accounting match QB', e);
      ToastHelper.show(
        'Accounting match QB',
        e.response.data?.error,
        5000,
        'danger'
      );
    }
  }

  @Action
  async QB(data) {
    this.context.commit('SET_LOADING_STATUS', true);
    try {
      const res = await AccountingService.QB(data);

      ToastHelper.show(
        'Quick Book',
        `Succeeded ${res.data.success} and Failed ${res.data.errors} `,
        5000,
        res.data.errors > 0 ? 'danger' : 'success'
      );
    } catch (e) {
      this.context.commit('SET_LOADING_STATUS', false);
      console.log('QB', e);
      ToastHelper.show('Quick Book', e.response.data?.error, 5000, 'danger');
    }
  }

  @Action
  async test() {
    console.log('TEST TEST TEST');
  }
}
