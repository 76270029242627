import { WoAction } from '@/shared/components/wo/models';
import { ToastHelper } from '@/utils/toast.util';
import { ApiService } from '../base/api.service';

class WorkOrderTrackingProvider extends ApiService {
  constructor() {
    super('orders');
  }

  async getWoTrackingTableDetails(type: string, id: String) {
    try {
      const res = await this.get(`${WoAction[type]}/${id}/events`);
      return res.data;
    }
    catch (e) {
      ToastHelper.show(
        'Error',
        'Error while loading Tracking Information',
        5000,
        'danger'
      );
      console.log(e);
    }
  }

  async getWoTrackingMapDetails(orderNumber: String, category: String) {
    try {
      const res = await this.get(`tracking/geo-tracking?orderNumber=${orderNumber}&category=${category}`);
      return res.data;
    }
    catch (e) {
      ToastHelper.show(
        'Error',
        'Error while loading vehicle location details',
        5000,
        'danger'
      );
      console.log(e);
    }
  }
  async getTrackingId(driverId: String, locationId: String, lat: any, lng: any) {
    try {
      const res = await this.get(`/tracking/track-id?driver-id=${driverId}&destination-location-id=${locationId}&lat=${lat}&lng=${lng} `);
      return res.data;
    }
    catch (e) {
      ToastHelper.show(
        'Error',
        'Error while loading tracking details',
        5000,
        'danger'
      );
      console.log(e);
    }
  }
}

export const WorkOrderTrackingService = new WorkOrderTrackingProvider();
