import moment from 'moment';
import Vue from 'vue';

import {
  DATE_API_FORMAT,
  DATE_VIEW_FULL_FORMAT,
  BS_DATEPICKER_FORMAT
} from './../../utils/date.util';

Vue.filter('date', function(value: string, format = DATE_VIEW_FULL_FORMAT) {
  if (value) {
    if (value.indexOf('/') !== -1) {
      return value;
    }

    if (value.indexOf('-') !== -1) {
      return moment(String(value), BS_DATEPICKER_FORMAT).format(format);
    }
    return moment(String(value), DATE_API_FORMAT).format(format);
  }
});
