import { Role, RolePermissions } from './permission.model';

export const hasPermission = (
  role: Role = Role.Default,
  action: string
): boolean => {
  console.log(role);
  if (role === Role.Default) {
    return false;
  }
  return (RolePermissions[role] as string[])?.indexOf(action) !== -1;
};
