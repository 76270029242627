export const permissions = {
  user: {
    create: 'createUser'
  },
  wo: {
    create: 'createWo',
    viewList: 'viewWoList',
    viewItem: 'viewWoItem',
    editItem: 'editWoItem'
  }
};

export enum Role {
  Default = 'DEFAULT',
  Dispatcher = 'DISPATCHER',
  Driver = 'DRIVER',
  Accountant = 'ACCOUNTANT',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPERADMIN',
  Carrier = 'CARRIER',
  SubDriver = 'SUBDRIVER'
}

export const RolePermissions: { [key: string]: string[] } = {
  [Role.Dispatcher]: [],
  [Role.Driver]: [],
  [Role.Accountant]: [],
  [Role.Admin]: [permissions.user.create],
  [Role.SuperAdmin]: [permissions.user.create]
};
