















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { JobsService } from '@/shared/services/jobs/jobs.service';

import EmailModal from '@/shared/components/modal/EmailModal.vue';
import { WoType } from '@/shared/components/wo/models/index';
import { deepClone } from '@/utils/utils';
import moment from 'moment';
import {
  DATE_EMAIL_FORMAT,
  DATE_API_FORMAT,
  DATE_TIME_EMAIL_FORMAT,
  convertDateFieldToAPIFormat,
  BS_DATEPICKER_WITH_TIME,
  lessThenCompare,
  DATE_API_FORMAT_WITHOUT_TIME,
  combineDateAndTime
} from '../../../../utils/date.util';
import { ToastHelper } from '../../../../utils/toast.util';
import DriverIdModal from '@/shared/components/modal/DriverIdModal.vue';
import { JobPayments } from '@/shared/models/driverIdModal.model';
import {
  DispatchScheduleLocation,
  LocationType
} from '@/shared/models/woSchedule.model';
import {
  errorByLocation,
  getScheduleOrderErrors
} from '@/shared/services/wo/schedule-validation';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: { EmailModal, DriverIdModal }
})
export default class WoAction extends Vue {
  @Prop() wo: any;
  @Prop() woForm: any;
  @Prop() formValidation: any;
  @Prop() driverIdToChange: {
    prevValue: string;
    value: string;
  };

  scheduleErrors = [];

  successMsg = this.$i18n.t('wo.savedSuccessfully');
  woLabel = this.$i18n.t('wo.workOrder');

  makeSaveRequest = false;
  jobPayments = null;
  editedForm = null;
  WoType = WoType;
  incomingSchedules = [];
  putRequest: JobPayments = {
    previousPlan: {
      schedules: [],
      jobPayments: []
    },
    currentSchedules: []
  };

  template = null;
  subject = null;
  clietEmails = null;
  emptySchedule: any = [];
  scheduleValidation: any = null;
  scheduleDatesOrderErrors: string[] = [];
  invalidDateTimePicker = 'Invalid date';
  rowsExpected = false;
  dates = ['actualIn', 'actualOut', 'scheduleIn'];
  errorModal = false;
  importWoModal = false;
  importWoApply = false;

  exportDisabled = false;

  canDeleteWO = true;

  constructor() {
    super();
  }

  created() {
    this.canDeleteWO = PermissionsService.can(
      'DSP / MAIN / BC DETAIL',
      'WO DELETE',
      'A/E'
    );
  }

  type(types: WoType[]) {
    return types.indexOf(this.wo.category) > -1;
  }

  isFormInValid() {
    if (this.formValidation.$anyError) {
      this.errorModal = true;
    }
    return this.formValidation.$anyError;
  }

  getScheduleDatesOrderErrors(): string[] {
    return errorByLocation(this.wo.schedules);
  }

  isCutOffValid() {
    if (this.editedForm.cutOff && this.editedForm.erd) {
      const inputDiffMinutes = lessThenCompare(
        this.editedForm.cutOff,
        this.editedForm.erd
      );
      return inputDiffMinutes >= 0 ? true : false;
    } else {
      return true;
    }
  }

  generateEmailTemplate(template, type = '') {
    const props = {
      containerNumber: this.wo.containerNumber,
      pulloutLocation: this.wo.pulloutLocation,
      pulloutSchedule: this.wo.pulloutSchedule
        ? moment(this.wo.pulloutSchedule, DATE_API_FORMAT).format(
            DATE_TIME_EMAIL_FORMAT
          )
        : '',
      deliveryLocation: this.wo.deliveryLocation,
      deliverySchedule: this.wo.deliverySchedule
        ? moment(this.wo.deliverySchedule, DATE_API_FORMAT).format(
            DATE_TIME_EMAIL_FORMAT
          )
        : '',
      lastFreeDay: this.wo.lastFreeDay
        ? moment(this.wo.lastFreeDay, DATE_API_FORMAT).format(DATE_EMAIL_FORMAT)
        : '',
      orderNumber: this.wo.orderNumber,
      customer:
        this.wo.poNumber ||
        this.wo.referenceNumber ||
        this.wo.masterBillOfLading,
      billTo: this.wo.billToName,
      type: type,
      returnFreeDay: this.wo.returnFreeDay
        ? moment(this.wo.returnFreeDay, DATE_API_FORMAT).format(
            DATE_EMAIL_FORMAT
          )
        : '',
      returnLocation: this.wo.returnLocation
    };

    this.template = this.$t(`wo.email.${template}.body`, props);
    this.subject = this.$t(`wo.email.${template}.subject`, props);
    this.clietEmails = this.wo.sendTo;
    this.$bvModal.show('EmailModal');
  }

  async callDriverIdModalWindow(isExport, isImport) {
    this.editedForm = this.woForm;
    this.editedForm.dropLive = this.wo.dropLive;
    this.editedForm.schedules = deepClone(this.wo.schedules);
    this.incomingSchedules = deepClone(this.wo.schedules);

    if (this.isFormInValid()) {
      return;
    }

    if (!this.isCutOffValid()) {
      return;
    }

    const isValidSchedule = this.validateSchedule(this.wo.schedules);
    this.scheduleErrors = getScheduleOrderErrors(this.wo.schedules);

    this.scheduleDatesOrderErrors = this.getScheduleDatesOrderErrors();

    if (
      this.scheduleErrors.length > 0 ||
      isValidSchedule.length > 0 ||
      this.scheduleDatesOrderErrors.length > 0 ||
      this.editedForm.schedules.length <= 1
    ) {
      if (this.editedForm && this.editedForm.schedules.length <= 1) {
        this.rowsExpected = true;
      }
      return;
    } else {
      this.saveWO(
        {
          jobPayments: this.wo.jobPayments,
          schedules: this.wo.schedules
        },
        isExport,
        isImport
      );
    }
  }

  async saveWO(putResponse, isExport, isImport) {
    this.editedForm.jobPayments = deepClone(putResponse.jobPayments);
    this.editedForm.schedules = deepClone(
      putResponse.schedules.map(i => {
        if (!i.scheduleIn?.length) {
          i.scheduleIn = null;
        }
        if (!i.actualIn?.length) {
          i.actualIn = null;
        }
        if (!i.actualOut?.length) {
          i.actualOut = null;
        }
        return i;
      })
    );
    if (this.makeSaveRequest) {
      await this.continueSave(isExport, isImport);
    }
    this.makeSaveRequest = false;
  }

  // generateEmailpuSchedule() {
  //   const props = {
  //     containerNumber: this.wo.containerNumber,
  //     pulloutLocation: this.wo.pulloutLocation,
  //     pulloutSchedule: moment(this.wo.pulloutSchedule, DATE_API_FORMAT).format(
  //       DATE_TIME_EMAIL_FORMAT
  //     ),
  //     billTo: this.wo.billTo
  //   };
  //   this.generateEmailTemplate('wo.email.puSchedule', props);
  // }

  // generateEmailDeliverySchedule() {
  //   const props = {
  //     containerNumber: this.wo.containerNumber,
  //     deliveryLocation: this.wo.deliveryLocation,
  //     deliverySchedule: moment(
  //       this.wo.deliverySchedule,
  //       DATE_API_FORMAT
  //     ).format(DATE_TIME_EMAIL_FORMAT),
  //     billTo: this.wo.billTo
  //   };
  //   this.generateEmailTemplate('wo.email.deliverySchedule', props);
  // }

  // generateEmailPodPol(type: string) {
  //   const props = {
  //     type,
  //     containerNumber: this.wo.containerNumber,
  //     deliveryLocation: this.wo.deliveryLocation,
  //     deliverySchedule: moment(
  //       this.wo.deliverySchedule,
  //       DATE_API_FORMAT
  //     ).format(DATE_TIME_EMAIL_FORMAT),
  //     billTo: this.wo.billTo
  //   };
  //   this.generateEmailTemplate('wo.email.podPol', props);
  // }

  // generateEmailDemurrage() {
  //   const props = {
  //     containerNumber: this.wo.containerNumber,
  //     deliveryLocation: this.wo.deliveryLocation,
  //     lastFreeDay: moment(this.wo.lastFreeDay, DATE_API_FORMAT).format(
  //       DATE_EMAIL_FORMAT
  //     ),
  //     orderNumber: this.wo.orderNumber,
  //     billTo: this.wo.billTo,
  //     customer:
  //       this.wo.poNumber ||
  //       this.wo.referenceNumber ||
  //       this.wo.masterBillOfLading
  //   };
  //   this.generateEmailTemplate('wo.email.demurrage', props);
  // }

  // generateEmailWaiting() {
  //   const props = {
  //     containerNumber: this.wo.containerNumber,
  //     deliveryLocation: this.wo.deliveryLocation,
  //     lastFreeDay: moment(this.wo.lastFreeDay, DATE_API_FORMAT).format(
  //       DATE_EMAIL_FORMAT
  //     ),
  //     orderNumber: this.wo.orderNumber,
  //     billTo: this.wo.billTo,
  //     customer:
  //       this.wo.poNumber ||
  //       this.wo.referenceNumber ||
  //       this.wo.masterBillOfLading
  //   };
  //   this.generateEmailTemplate('wo.email.waiting', props);
  // }
  deleteConfirm() {
    this.$bvModal
      .msgBoxConfirm('Are you sure that you want to delete it?', {
        title: 'Delete Order',
        headerClass: 'p-10 d-flex align-items-center',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-10',
        hideHeaderClose: false,
        centered: true
      })
      .then(res => {
        if (res) {
          this.deleteOrder();
        }
      })
      .catch(e => e);
  }

  completeConfirm(label) {
    this.$bvModal
      .msgBoxConfirm(`Are you sure that you want to ${label} order?`, {
        title: 'Order confirmation',
        headerClass: 'p-10 d-flex align-items-center',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-10',
        hideHeaderClose: false,
        centered: true
      })
      .then(res => {
        if (res) {
          this.completeOrder();
        }
      })
      .catch(e => e);
  }

  async save(isExport = false, isImport = false) {
    this.makeSaveRequest = true;
    this.callDriverIdModalWindow(isExport, isImport);
  }

  async continueSave(isExport, isImport) {
    // this.jobPayments.forEach(jobPayment => {
    //   changes.jobPayments.forEach(change => {
    //     if (jobPayment.jobUuid === change.jobUuid) {
    //       payment.amount = +change.amount;
    //     }
    //   });
    // });
    // console.log('[finish]: ', this.jobPayments);

    this.scheduleValidation = '';
    this.emptySchedule = '';
    this.rowsExpected = false;

    this.updateSchedules();

    try {
      await WorkOrderService.update(
        this.wo.category,
        this.wo.orderNumber,
        this.editedForm
      );

      if (isExport) {
        this.exportOrder();
      } else if (isImport) {
        if (
          !!this.wo.accountPayables.length ||
          !!this.wo.accountReceivables.length
        ) {
          ToastHelper.show(
            'Import WO',
            `To remove this WO, please remove all the AP/AR.`,
            8000,
            'warning'
          );
        } else {
          this.importWoModal = true;
        }
      } else {
        this.$emit('woUpdated');

        ToastHelper.show(
          this.wo.category,
          `${this.woLabel} ${this.wo.orderNumber} ${this.successMsg}`,
          8000,
          'success'
        );
      }
    } catch (e) {
      if (isExport) this.exportDisabled = false;

      ToastHelper.show(
        this.wo.category,
        `${this.woLabel} ${this.wo.orderNumber} ${e.message}`,
        8000,
        'danger'
      );
    }
  }

  private updateSchedules() {
    const returnSchedule = this.getLocation(LocationType.RETURN);
    const deliverySchedule = this.getLocation(LocationType.DELIVERY);
    const pulloutSchedule = this.getLocation(LocationType.PULLOUT);
    if (returnSchedule) {
      this.editedForm.returnLocationName = returnSchedule.locationName;
      this.editedForm.returnLocation = returnSchedule.location;
      if (
        returnSchedule.scheduleIn &&
        returnSchedule.scheduleIn != 'Invalid date' &&
        returnSchedule.scheduleInTime
      ) {
        this.editedForm.returnSchedule = combineDateAndTime(
          returnSchedule.scheduleIn,
          returnSchedule.scheduleInTime
        );
      } else if (
        returnSchedule.scheduleIn &&
        returnSchedule.scheduleIn != 'Invalid date'
      ) {
        this.editedForm.returnSchedule = combineDateAndTime(
          returnSchedule.scheduleIn,
          null
        );
      } else {
        this.editedForm.returnSchedule = '';
      }
    } else {
      this.editedForm.returnLocationName = '';
      this.editedForm.returnLocation = '';
      this.editedForm.returnSchedule = '';
    }

    if (pulloutSchedule) {
      this.editedForm.pulloutLocationName = pulloutSchedule.locationName;
      this.editedForm.pulloutLocation = pulloutSchedule.location;
    }

    if (deliverySchedule) {
      this.editedForm.deliveryLocationName = deliverySchedule.locationName;
      this.editedForm.deliveryLocation = deliverySchedule.location;
    }
  }

  exportOrder() {
    WorkOrderService.exportOrder(this.wo.category, this.wo.orderNumber)
      .then(data => {
        if (data) {
          this.exportDisabled = true;
          this.$emit('woUpdated');
        }
      })
      .catch(() => {
        this.exportDisabled = false;
      });
  }

  importOrder() {
    this.importWoModal = false;
    this.$emit('woUpdated');
    WorkOrderService.importOrder(this.wo.category, this.wo.orderNumber);
  }

  deleteOrder() {
    WorkOrderService.deleteOrder(this.wo.category, this.wo.orderNumber).then(
      res => {
        if (res === 200) this.$bvModal.hide('wo-base-modal');
      }
    );
  }

  completeOrder() {
    WorkOrderService.completeOrder(
      this.wo.category,
      this.wo.orderNumber,
      'wo',
      this.wo.completed
    ).then(() => {
      this.wo.completed = !this.wo.completed;
    });
  }

  validateSchedule(schedules) {
    const mappedSchedules: any[] = [];

    schedules.forEach((el, index) => {
      let isValidActualInDate = true;

      if (el.actualInTime && el.actualIn) {
        isValidActualInDate = moment(
          `${moment(el.actualIn, DATE_API_FORMAT).format(
            DATE_API_FORMAT_WITHOUT_TIME
          )} ${el.actualInTime}`,
          BS_DATEPICKER_WITH_TIME
        ).isBefore(moment());
      }

      // ||
      //   ((!el.scheduleIn || el.scheduleIn === this.invalidDateTimePicker) &&
      //     el.scheduleInTime &&
      //     el.scheduleInTime !== this.invalidDateTimePicker) ||
      //   (el.actualIn !== this.invalidDateTimePicker &&
      //     el.actualInTime === this.invalidDateTimePicker) ||
      //   (el.actualIn === this.invalidDateTimePicker &&
      //     el.actualInTime !== this.invalidDateTimePicker)

      if (!isValidActualInDate || !el.location) {
        const reqFields = {
          index: ++index,
          location: el.location
          // scheduleIn: el.scheduleIn,
          // scheduleInTime: el.scheduleInTime,
          // actualIn: el.actualIn,
          // actualInTime: el.actualInTime,
          // actualOut: el.actualOut,
          // actualOutTime: el.actualOutTime
        };
        mappedSchedules.push(reqFields);
      }
    });
    return mappedSchedules;
  }

  translateScheduleValidationMessage(message, field, row) {
    return this.$i18n
      .t(message, {
        locationField: this.$i18n.t(field),
        row
      })
      .toString();
  }

  hide() {
    this.$bvModal.hide('wo-base-modal');
  }

  getLocation(locationType: string) {
    return this.wo.schedules.find(i => i.locationType === locationType);
  }
}
