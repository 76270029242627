import { BaseClass } from '../base/base.service';
import { FilterConditions } from '../filter/filter.service';
import { DATE_API_FORMAT, BS_DATEPICKER_FORMAT } from '@/utils/date.util';
import moment from 'moment';

export class CompanyProvider extends BaseClass {
  constructor() {
    super('master-data/companies');
  }

  async getCompaniesIdentifiers(query: string, fields = ['id']) {
    const search = query.toLocaleUpperCase();

    const payload = fields.map(field => ({
      field,
      search,
      condition: FilterConditions.BeginsWith
    }));

    const res = await this.get('/identifiers', {
      filter: this.transformFiltersToJson(payload),
      limit: -1
    });
    return res.data.data;
  }

  async getCompaniesIdentifiersForAPAR(search: string, type: string) {
    // limit: -1 means get ALL
    const res = await this.get('/identifiers', {
      filter: this.transformFiltersToJson([
        {
          search,
          field: 'name',
          condition: FilterConditions.BeginsWith
        },
        { field: type, search: true, condition: FilterConditions.Equal }
      ]),
      limit: -1
    });

    return res.data.data;
  }

  async getGLCodes(field: string, search: string) {
    // limit: -1 means get ALL
    const res = await this.get('/identifiers', {
      filter: this.transformFiltersToJson([
        {
          search,
          field,
          condition: FilterConditions.BeginsWith
        }
      ]),
      limit: -1
    });

    return res.data.data;
  }

  async getCompanies(filter?) {
    // limit: -1 means get ALL
    const res = await this.get('/', {
      filter,
      limit: -1
    });

    // let dateKeys = [
    //   "hireDate",
    //   "terminateDate",
    //   "birthday",
    //   "legalExpirationDate",
    //   "companyLicenseExpirationDate",
    //   "medicalExpirationDate",
    //   "twicExpirationDate",
    //   "insuranceExpirationDate",
    //   "mvrDate",
    //   "roadTestDate",
    //   "drugTestDate"
    // ]

    // res.data.data.forEach(element => {
    //   dateKeys.forEach(key => {
    //     if (element[key]) {
    //       element[key] = moment(element[key], DATE_API_FORMAT).format(BS_DATEPICKER_FORMAT);
    //     }
    //   });
    // });
    return res.data;
  }

  async createCompany(data) {
    const res = await this.post('/', data);
    return res.data.data;
  }

  async getCompanyById(id: string) {
    const res = await this.get(`/${id}`);

    const dateKeys = [
      'hireDate',
      'terminateDate',
      'birthday',
      'legalExpirationDate',
      'companyLicenseExpirationDate',
      'medicalExpirationDate',
      'twicExpirationDate',
      'insuranceExpirationDate',
      'mvrDate',
      'roadTestDate',
      'drugTestDate'
    ];

    dateKeys.forEach(key => {
      if (res.data[key]) {
        res.data[key] = moment(res.data[key], DATE_API_FORMAT).format(
          BS_DATEPICKER_FORMAT
        );
      }
    });

    const phones = [
      'cellPhone',
      'homePhone',
      'emergencyContact',
      'emergencyPhone'
    ];

    phones.forEach(phone => {
      if (res.data[phone]) {
        const phoneMas = [...res.data[phone]];
        //(123) 123-1231
        phoneMas.splice(0, 0, '(');
        phoneMas.splice(4, 0, ')');
        phoneMas.splice(5, 0, ' ');
        phoneMas.splice(9, 0, '-');
        res.data[phone] = phoneMas.join('');
      }
    });

    return res.data;
  }

  async saveCompany(id: string, data) {
    const res = await this.put(`/${id}`, data);
    return res;
  }

  async getCompanyFileLink(id: string, category: string) {
    const res = await this.get(`/${id}/files/${category}`);
    return res.data;
  }

  async deleteCompanyFileById(id: string, category: string) {
    const res = await this.delete(`/${id}/files/${category}`);
  }

  async changeCompanyActive(id: string, data) {
    const res = await this.patch(`/${id}`, data);
    return res.data;
  }
  async changeCompanyTracking(id: string, data) {
    const res = await this.patch(`/${id}`, data);
    return res.data;
  }
}

export const CompanyService = new CompanyProvider();
