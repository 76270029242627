












import { Component, Prop, Vue } from 'vue-property-decorator';

import { DATE_TIME_FORMAT } from '@/utils/date.util';
import moment from 'moment';

@Component({})
export default class DateMonthPicker extends Vue {
  @Prop() format: string;
  selectedMonth = moment().format('MMMM');

  constructor() {
    super();
  }

  get months() {
    return moment.months();
  }

  changeMonth() {
    this.$emit(
      'input',
      this.format
        ? moment(this.selectedMonth, 'MMMM').format(this.format)
        : this.selectedMonth
    );
  }
}
