













import { Component, Vue } from 'vue-property-decorator';

import { setI18nLanguage } from '@/i18n/index';

@Component
export default class LanguageMenu extends Vue {
  setI18nLanguage = setI18nLanguage;
}
