export enum LocationType {
  DELIVERY = 'DELIVERY',
  PULLOUT = 'PULLOUT',
  PICKUP = 'PICKUP',
  RETURN = 'RETURN'
}

export enum DropLiveType {
  DROP = 'DROP',
  LIVE = 'LIVE'
}

export interface DispatchScheduleLocation {
  locationType: LocationType;
  driverId: string;
  scheduleIn: string;
  scheduleInTime?: string;
  uuid?: string;
  shouldReassignDriver?: boolean;
}
