











































import { Component, Vue, Prop } from 'vue-property-decorator';
import DriversSearchModal from '@/pages/Main/components/DetailInfo/DriversSearchModal.vue';
import DriverInput from './DriverInput.vue';
import {
  DispatchScheduleLocation,
  LocationType
} from '@/shared/models/woSchedule.model';
import {
  BS_DATEPICKER_WITH_TIME,
  convertDateFieldToAPIFormat
} from '@/utils/date.util';
import { JobPayments } from '@/shared/models/driverIdModal.model';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import DriverIdModal from '@/shared/components/modal/DriverIdModal.vue';
import { deepClone } from '@/utils/utils';

@Component({
  components: {
    DriversSearchModal,
    DriverInput,
    DriverIdModal
  }
})
export default class DriverInputWrapper extends Vue {
  @Prop(String) value: string;
  @Prop(String) date: string;
  @Prop(String) time: string;
  @Prop(String) locationType: LocationType;
  @Prop(String) location: any;
  @Prop(Boolean) checkLocation: boolean;
  @Prop(Object) wo: any;
  @Prop(Boolean) form: boolean;
  @Prop(String) uuid: string;
  @Prop(String) jobUuid: string;

  amaunModal = false;
  driverSearchModal = false;
  driverId: string = null;

  jobPayments: JobPayments = null;

  constructor() {
    super();
  }

  openDriverSearchModal({ activeUiid, openDriverModal }) {
    if (this.checkLocation && !this.location) {
      return;
    }

    if (activeUiid) {
      this.$emit('unAssignDriver', this.uuid);
    }

    if (openDriverModal) {
      this.driverSearchModal = true;
    }
  }

  openAmaunModal(driverId: string) {
    this.driverSearchModal = false;
    this.driverId = driverId;

    if (this.form) {
      this.$emit('input', this.driverId);
      return;
    }

    const mappedSchedules = deepClone(this.wo.schedules);
    const uniqueUuid = mappedSchedules.find(
      row => row.locationType === this.locationType
    );

    const data: DispatchScheduleLocation[] = [
      {
        uuid: this.uuid ? this.uuid : uniqueUuid.uuid,
        locationType: this.locationType,
        driverId,
        shouldReassignDriver: uniqueUuid.jobUuid ? true : false,
        scheduleIn: convertDateFieldToAPIFormat(
          `${this.date} ${this.time}`,
          BS_DATEPICKER_WITH_TIME
        )
      }
    ];

    const jobUuidArray = mappedSchedules.map(item => item.jobUuid);

    const actualWOPayments = this.wo.jobPayments.filter(payment =>
      jobUuidArray.find(jobUuid => jobUuid === payment.jobUuid)
    );

    if (actualWOPayments && actualWOPayments.length !== 0 && this.jobUuid) {
      const activeJobUuid = actualWOPayments.findIndex(
        job => job.jobUuid === this.jobUuid
      );
      actualWOPayments[activeJobUuid].shouldSkip = false;
    }

    mappedSchedules.forEach(item => {
      item.scheduleIn = convertDateFieldToAPIFormat(
        `${item.scheduleIn} ${item.scheduleInTime}`,
        BS_DATEPICKER_WITH_TIME
      );
    });

    this.jobPayments = JobsService.getDispatchJobPayments(
      data,
      mappedSchedules,
      actualWOPayments
    );

    this.wo = JobsService.updateSchedules(
      this.wo,
      this.jobPayments.currentSchedules
    );

    this.amaunModal = true;
  }

  save({ jobPayments, schedules }) {
    this.amaunModal = false;
    this.$emit('input', this.driverId);
    this.$emit('updateSchedule', { jobPayments, schedules });
  }
}
