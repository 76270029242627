










































































import { WoType } from '@/shared/components/wo/models';
import { LocationType } from '@/shared/models/woSchedule.model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ScheduleAreaHeader extends Vue {
  @Prop() wo: any;
  woType = WoType;

  constructor() {
    super();
  }

  addRow() {
    this.$emit('addRow', {
      locationType: LocationType.PULLOUT,
      driverId: null
    });
  }

  isPullOutRow() {
    const pullOut = this.wo.schedules.find(
      row => row.locationType === LocationType.PULLOUT
    );

    return !pullOut ? true : false;
  }
}
