export const containerType = [
  {
    id: 'DRY',
    code: 'DRY'
  },
  {
    id: 'REEFER',
    code: 'REEFER'
  },
  {
    id: 'FLATRACK',
    code: 'FLAT RACK'
  },
  {
    id: 'TRIAXLE',
    code: 'TRI AXLE'
  },
  {
    id: 'OPENTOP',
    code: 'OPEN TOP'
  },
  {
    id: 'CFS',
    code: 'CFS'
  },
  {
    id: 'FLATBED',
    code: 'FLATBED'
  },
  {
    id: 'SINGLEDROP',
    code: 'SINGLE DROP'
  },
  {
    id: 'DOUBLEDROP',
    code: 'DOUBLE DROP'
  }
];

export const containerSize = [
  {
    id: '20ST',
    code: '20 ST'
  },
  {
    id: '40ST',
    code: '40 ST'
  },
  {
    id: '40HC',
    code: '40 HC'
  },
  {
    id: '45',
    code: '45'
  },
  {
    id: '48',
    code: '48'
  },
  {
    id: '53',
    code: '53'
  },
  {
    id: 'TRL',
    code: 'TRL'
  }
];

export const chassisPool = [
  { value: 'POOL', text: 'POOL', disabled: false },
  { value: 'NGL', text: 'NGL', disabled: false },
  { value: 'AIM', text: 'AIM', disabled: false },
  { value: 'APL', text: 'APL', disabled: false },
  { value: 'CS', text: 'CHINASHIPPING', disabled: false },
  { value: 'CSNA', text: 'CSNA', disabled: false },
  { value: 'DCLI', text: 'DCLI', disabled: false },
  { value: 'EG', text: 'EVERGREEN', disabled: false },
  { value: 'FLEXI', text: 'FLEXI', disabled: false },
  { value: 'GACP', text: 'GACP', disabled: false },
  { value: 'GCCP', text: 'GCCP', disabled: false },
  { value: 'HD', text: 'HD', disabled: false },
  { value: 'KLINE', text: 'K-LINE', disabled: false },
  { value: 'LABP', text: 'LABP', disabled: false },
  { value: 'MOL', text: 'MOL', disabled: false },
  { value: 'MTSN', text: 'MATSON', disabled: false },
  { value: 'POP', text: 'POP', disabled: false },
  { value: 'SEA', text: 'SEALAND', disabled: false },
  { value: 'TAXZ', text: 'TAXZ', disabled: false },
  { value: 'TNWA', text: 'TNWA', disabled: false },
  { value: 'TPSP', text: 'TPSP', disabled: false },
  { value: 'WCCP', text: 'WCCP', disabled: false },
  { value: 'YM', text: 'YANGMING', disabled: false }
];

export const shippingLine = [
  { id: 'ACL', code: 'ACL' },
  { id: 'APL', code: 'APL' },
  { id: 'CS', code: 'CHINASHIPPING' },
  { id: 'CMA', code: 'CMA/CGM' },
  { id: 'COS', code: 'COSCO' },
  { id: 'CSAV', code: 'CSAV' },
  { id: 'EG', code: 'EVERGREEN' },
  { id: 'SUD', code: 'HAMBURG-SUD' },
  { id: 'HL', code: 'HAPAG-LLOYD' },
  { id: 'HRZN', code: 'HORIZONELINE' },
  { id: 'HD', code: 'HYUNDAI' },
  { id: 'KLINE', code: 'K-LINE' },
  { id: 'MAE', code: 'MAERSK' },
  { id: 'MATS', code: 'MATS' },
  { id: 'MOL', code: 'MOL' },
  { id: 'MSC', code: 'MSC' },
  { id: 'NYK', code: 'NYK' },
  { id: 'ONE', code: 'ONE' },
  { id: 'OOCL', code: 'OOCL' },
  { id: 'PIL', code: 'PIL' },
  { id: 'PO', code: 'POSHIPPING' },
  { id: 'SEA', code: 'SEALAND' },
  { id: 'SM', code: 'SM' },
  { id: 'SS', code: 'SS' },
  { id: 'ANL', code: 'USLINE/ANL' },
  { id: 'WH', code: 'WANHAI' },
  { id: 'YM', code: 'YANGMING' },
  { id: 'ZIM', code: 'ZIMLINE' }
];
