























import { hasPermission } from '../../../utils/permissions/permissions.util';
import { permissions } from '../../../utils/permissions/permission.model';
import { Component, Vue } from 'vue-property-decorator';
import { UserModule, DivisionModule } from '../../../store';

@Component
export default class UserMenu extends Vue {
  hasPermission = hasPermission;
  permissions = permissions;

  userModule = UserModule;
  divisionModule = DivisionModule;

  logOut() {
    this.divisionModule.cleanDivisions();
    this.userModule.logout();
  }
}
