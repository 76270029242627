/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_N9BhX5e9e',
  aws_user_pools_web_client_id: '1reqd11dj9j423c209h9gkmvcj',
  oauth: {}
};

export default awsmobile;
