import { AdminConfService } from './../../shared/services/admin-conf/admin-conf.service';
import { Route, NavigationGuardNext } from 'vue-router';
import { DivisionModule, UserModule } from '@/store';
import { AuthService } from '@/shared/services/auth.service';
import { ToastHelper } from '@/utils/toast.util';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  if (DivisionModule.division || !UserModule.authorized) {
    next();
  } else {
    try {
      const divisionsResponse = await AuthService.getCurrentDivisions();
      await AdminConfService.loadConfig();

      const defaultDivision: string = divisionsResponse.data?.division;
      const divisions: string[] = divisionsResponse.data?.divisions;

      if (!defaultDivision) {
        throw new Error('Server does not return the default division');
      }

      if (!divisions || !Array.isArray(divisions)) {
        throw new Error('Server does not return the division list');
      }

      //TODO: DELETE workaround which added to make systems work before all users will get division list
      const divisionSet: Set<string> = new Set(divisions);
      divisionSet.add(defaultDivision);
      DivisionModule.setDefaultDivision(defaultDivision);
      const newDivisions = Array.from(divisionSet);

      const localDivision = localStorage.getItem('division');

      if (newDivisions.indexOf(localDivision) > -1) {
        DivisionModule.setDivision(localDivision);
      } else {
        DivisionModule.setDivision(defaultDivision);
      }

      DivisionModule.setDivisionsList(newDivisions);
      next();
    } catch (err) {
      console.error('Getting division list Error', err);
      ToastHelper.show(
        'Divisions',
        err.response?.data?.error || err.message,
        5000,
        'danger'
      );
    }
  }
};
