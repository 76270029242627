












import { Component, Vue, Watch } from 'vue-property-decorator';
import { Hub } from 'aws-amplify';
import { Auth } from 'aws-amplify';

import { UserModule } from './store';
import { WorkOrderEarlyWarning } from '@/shared/models/wo.model';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';

@Component({})
export default class App extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    this.classes = generateRouterClasses(this.$route);
    this.checkEarlyWarnings();
  }
  userModule = UserModule;

  earlyWarningWindowOpened = false;

  classes: string[] = [];

  get userEmail() {
    return this.userModule?.currentUser?.email;
  }

  get isAuthorized() {
    return this.userModule.isAuthorized;
  }

  async created() {
    this.getCurrentUser();
    Hub.listen('auth', () => {
      this.getCurrentUser();
    });
  }

  async getCurrentUser() {
    this.userModule.fetchUser().then(user => {
      if (user) {
        this.userModule.getUserPermissions();
        this.checkEarlyWarnings();
      }
    });
  }

  checkEarlyWarnings() {
    if (!localStorage.getItem('EarlyWindow')) {
      const routeData = this.$router.resolve({ name: 'early-warning-modal' });
      window.open(routeData.href, 'EarlyWindow', 'height=640,width=960');
      localStorage.setItem('EarlyWindow', 'true');
    }
  }
}
