import moment from 'moment';

export const isLessThenToday = (message: string) => (date: moment.Moment) => {
  return date.diff(moment(), 'days') < 0 ? message : null;
};

export const isLessTodayBy = (days: number) => (date: moment.Moment) => {
  return date.diff(moment(), 'days') < days;
};

export const isMoreTodayBy = (days: number) => (date: moment.Moment) => {
  return date.diff(moment(), 'days') > days;
};

export const dpPipe = (...args) => date => {
  return args.reduce(
    (currentValue, currentFunction) =>
      currentValue ? currentValue : currentFunction(date),
    null
  );
};

export const isLessOrMoreTodayBy = (days: number, message: string) => (
  date: moment.Moment
) => (dpPipe(isLessTodayBy(-days), isMoreTodayBy(days))(date) ? message : null);

export const scheduleHandler = isLessOrMoreTodayBy(90, `datePicker.90DaysDiff`);
