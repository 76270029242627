var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hideHeaedr)?_c('div',{staticClass:"tms-header"},[_c('b-navbar',{attrs:{"fixed":"top","toggleable":"lg","variant":"tms","type":"tms"}},[_c('b-navbar-brand',{staticClass:"tms-header__logo",attrs:{"to":"/"}},[_c('div',{staticClass:"logo-holder"},[_c('div',{staticClass:"logo_img_wrapper"},[_c('img',{staticClass:"tms-header__logo_image",attrs:{"src":"img/icons/menu-logo.png","alt":"TMS","id":"logo_img"}})])])]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(_vm.page === 'yms')?_c('b-navbar-nav',_vm._l((_vm.ymsChunks),function(chunk){return _c('b-nav-item',{key:chunk.to,staticClass:"text-uppercase",class:{ active: _vm.$route.name === chunk.name },attrs:{"to":chunk.to}},[_vm._v(" "+_vm._s(chunk.key)+" ")])}),1):_vm._e(),(_vm.page === 'main')?_c('b-navbar-nav',[_c('b-nav-item-dropdown',{staticClass:"text-uppercase nav-itmem__division",attrs:{"text":_vm.divisionModule.division || 'DIV'}},_vm._l((_vm.divisionModule.divisionsList),function(chunk){return _c('b-dropdown-item',{key:chunk,staticClass:"text-uppercase",on:{"click":function () { return _vm.updateDivision(chunk); }}},[_vm._v(" "+_vm._s(chunk)+" ")])}),1),_vm._l((_vm.menuConfig),function(menuItem,i){return _c('Fragment',{key:menuItem.pageName},[(
              menuItem.pageData.active && menuItem.pageData.type == 'single'
            )?_c('Fragment',[_c('b-nav-item',{staticClass:"text-uppercase",class:({ active: _vm.$route.name === menuItem.routeName },
                menuItem.pageData.class),attrs:{"to":menuItem.pageData.url}},[_vm._v(" "+_vm._s(_vm.$t(menuItem.pageData.title))+" ")])],1):_vm._e(),(
              menuItem.pageData.active && menuItem.pageData.type == 'multiple'
            )?_c('Fragment',[_c('b-nav-item-dropdown',{directives:[{name:"tms-show-menu-on-hover",rawName:"v-tms-show-menu-on-hover"}],ref:("dropdown" + i),refInFor:true,staticClass:"\n              text-uppercase\n              dropdown-menu_section-container\n            ",class:({ active: _vm.$route.path.includes(menuItem.routeName) },
                menuItem.pageData.class),attrs:{"id":("dropdown" + i),"text":_vm.$t(menuItem.pageData.title)}},_vm._l((menuItem.pageData.categories),function(chunk){return _c('div',{key:chunk.name,staticClass:"dropdown-menu_section",class:['dropdown-menu_section__' + chunk.routeChunk]},[_c('p',{staticClass:"dropdown-menu_section_label"},[_vm._v(_vm._s(chunk.name))]),_vm._l((chunk.links),function(child){return _c('b-dropdown-item',{key:((chunk.type) + "-" + (child.type)),staticClass:"dispatchMenu-subtype text-uppercase",class:['dropdown-menu_section_item__' + chunk.routeChunk],attrs:{"to":("/" + (menuItem.pageData.routeName) + "/" + (chunk.routeChunk) + "/" + (child.routeChunk))}},[_vm._v(" "+_vm._s(_vm.$t(child.title))+" ")])})],2)}),0)],1):_vm._e(),(
              menuItem.pageData.active && menuItem.pageData.type == 'dropdown'
            )?_c('Fragment',[_c('b-nav-item-dropdown',{directives:[{name:"tms-show-menu-on-hover",rawName:"v-tms-show-menu-on-hover"}],ref:("dropdown" + i),refInFor:true,staticClass:"text-uppercase",class:({ active: _vm.$route.name === menuItem.routeName },
                menuItem.pageData.class),attrs:{"id":("dropdown" + i),"text":_vm.$t(menuItem.pageData.title)}},_vm._l((menuItem.pageData.links),function(chunk){return _c('b-dropdown-item',{key:chunk.type,staticClass:"text-uppercase",class:['dropdown-item__' + chunk.routeChunk],attrs:{"to":{
                  params: { type: chunk.type },
                  path: ("/" + (menuItem.pageData.routeName) + "/" + (chunk.routeChunk))
                }}},[_vm._v(" "+_vm._s(_vm.$t(chunk.title))+" ")])}),1)],1):_vm._e(),(menuItem.pageName == 'admin')?_c('Fragment',[_c('b-nav-item-dropdown',{directives:[{name:"tms-show-menu-on-hover",rawName:"v-tms-show-menu-on-hover"}],ref:("dropdown" + (_vm.menuConfig.length)),refInFor:true,staticClass:"text-uppercase",class:{ active: _vm.$route.path.includes('/admin/') },attrs:{"id":("dropdown" + (_vm.menuConfig.length)),"text":'ADMIN'}},_vm._l((_vm.adminChunks),function(chunk){return _c('b-dropdown-item',{key:chunk.key,staticClass:"text-uppercase",attrs:{"to":chunk.to},on:{"click":function () { return chunk.cb && chunk.cb(); }}},[_vm._v(_vm._s(chunk.key))])}),1)],1):_vm._e()],1)})],2):_vm._e(),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('language-menu'),_c('user-menu')],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }