export const es = {
  action: {},
  searchBtn: 'Search',
  excelBtn: 'excel',
  emailBtn: 'email',
  cancelBtn: 'Cancelar',
  navigation: {
    order: {
      title: 'order'
    },
    dispatch: {
      title: 'dispatch',
      book: 'booking',
      unavailable: 'una',
      available: 'ava',
      delivery: 'dlv',
      return: 'rtn'
    },
    main: {
      main: 'MAIN',
      import: 'IMPORT',
      export: 'EXPORT',
      van: 'VAN',
      cytocy: 'CY to CY',
      bareChassis: 'BARE CHASSIS'
    },
    reports: {
      report: 'TMS Report',
      pullout: 'PULLOUT',
      delivery: 'DELIVERY',
      return: 'RETURN',
      demurrage: 'DEMURRAGE',
      perdiem: 'PERDIEM',
      statistics: 'STATISTICS',
      'early-warning': 'EARLY WARNING LIST'
    },
    driver: {
      title: 'DRIVER',
      pod: 'POD LIST',
      bill: 'BILL CONFIRM',
      manifest: 'MANIFEST'
    },
    map: {
      title: 'map',
      map: 'map',
      geofence: 'geofence'
    },
    master: {
      title: 'master',
      driver: 'driver',
      company: 'company',
      account: 'account code'
    },
    user: {
      user: 'Usuaria',
      profile: 'Perfil',
      createUser: 'Crear usuario',
      signOut: 'Cerrar sesión'
    },
    import: {
      import: 'Importar',
      dataInput: 'Entrada de datos',
      main: 'Principal',
      dispatch: 'Envío',
      order: 'PEDIDO'
    },
    export: {
      booking: 'Reserva',
      dataInput: 'Entrada de datos',
      main: 'Principal',
      dispatch: 'Envío',
      export: 'Exportar'
    },

    language: 'Español'
  },
  order: {
    billTo: 'Facturar a',
    mbl: 'MBL #',
    houseBL: 'Casa BL #',
    referenceNumber: 'REF #',
    poNumber: 'PO #',
    deliveryNumber: 'Delivery #',
    cont: 'Cont #',
    trailNumber: 'Trailer #',
    containerSize: 'Tamaño',
    containerType: 'Tipo',
    vessel: 'VESSEL',
    chassisNumber: 'Chassis #',
    chassisPool: 'PISCINA',
    vesselEta: 'ETA',
    ava: 'AVA',
    dsc: 'DSC',
    obl: 'OBL',
    cus: 'CUS',
    oth: 'OTH',
    lfd: 'LFD',
    freeDays: 'Días libres',
    rfd: 'RFD',
    seal: 'SELLO',
    commodity: 'COMMODITY',
    weight: 'WGT',
    quantity: 'QTY',
    unit: 'Unidad',
    sendTo: 'Enviar a',
    remarks: 'Observaciones',
    pullOut: 'PULLOUT',
    puSchedule: 'Programación P / OUT',
    delivery: 'Entrega *',
    deliverySchedule: 'Horario DILV',
    return: 'Regresar',
    returnSchedule: 'Programación RTN',
    oh: 'O / H',
    ow: 'O / W',
    haz: 'HAZ',
    hot: 'CALIENTE',
    qty: 'Cantidad',
    amt: 'Cantidad',
    rate: 'Tasa',
    arName: 'Nombre de A / R',
    arCode: 'Código A / R',
    ssl: 'SSL *',
    dropLive: 'DROP / LIVE',
    bondEntry: 'Bond Entry #',
    hacer: 'HACER *',
    cf: 'CF',
    other: 'Otro',
    categories: 'Categorías',
    bookingNumber: 'Booking #',
    wms: 'WMS',
    erd: 'ERD',
    cutOff: 'CUT OFF',
    bk: 'BK #',
    avaDsc: 'DISPONIBILIDAD DSC',
    avaDate: 'FECHA DE DISPONIBILIDAD',
    tare: 'TARA (lb)',
    st: 'S / T',
    mblBk: 'MBL / BK #',
    loadCFM: 'Load CFM',
    emptyCFM: 'Empty CFM'
  },
  common: {
    email: 'Dirección de correo electrónico',
    close: 'cerca'
  },
  validations: {
    required: 'Este es un campo obligatorio.',
    max: 'Cantidad máxima de caracteres {number}',
    maxIntegers: 'Cantidad máxima de caracteres {number} y solo enteros',
    maxWithDecimal:
      'Cantidad máxima de caracteres {number} y solo 2 dígitos después del punto',
    reqMax:
      'Este es un campo obligatorio y cuenta máxima de caracteres {number}',
    lettersAndDigitsReq:
      'Este campo es obligatorio y debe tener {letters} letras y {digits} dígitos',
    lettersAndDigitsOpt:
      'El campo debe tener {letters} letras y {digits} dígitos',
    isValidPoandDlv: 'Los campos P/O Schedule y DLV Schedule son obligatorios.',
    invalidPO: 'El campo P/O Schedule es obligatorio.',
    invalidDLV: 'El campo de programación de DLV es obligatorio.',
    invalidReturn: 'The RTN Schedule date and time must be later than P/OUT Schedule',
    laterThenMsg: 'The {current} date and time must be later than {related}',
    bcScheduleInvalidLocation:
      'The LOCA ID must be same to {locationField} field in row {row}',
    bcScheduleInvalidDelivery:
      'The LOCA ID must be same to {locationField} field in schedule',
    bcScheduleRequiredInRow: 'are required in row',
    bcScheduleFields: 'The fields',
    notEmptyFile: 'The file cannot be empty',
    rowsExpected: 'At least 2 rows in schedule are expected.',
    phoneFormat: 'Phone format: (###) ###-####',
    shouldNotBeEarlier: '{current} should not be earlier than {related}'
  },
  contextMenu: {
    detailInfo: 'Detail info',
    billingInfo: 'Billing info',
    document: 'Document',
    tracking: 'Tracking',
    basicInfo: 'Basic info',
    location: 'Location',
    availability: 'Availability',
    schedule: 'Schedule',
    memo: 'Memo'
  },
  scheduleTable: {
    locationId: 'LOCA ID',
    locationInfo: 'LOCATION INFO',
    city: 'Ciudad',
    state: 'ST',
    in: 'EN',
    out: 'FUERA',
    scheduleDate: 'SCHE DATE',
    time: 'tiempo',
    actualDate: 'FECHA ACTUAL',
    memo: 'MEMO',
    driverId: 'ID de DRV',
    estado: 'ESTADO',
    updatedBy: 'Actualizado por',
    notActualInDateButTime: 'ACTUAL IN DATE si se nombra TIME',
    notActualInTimeButDate: 'ACTUAL IN TIME si se nombra FECHA',
    notActualOutDateButTime: 'FECHA DE SALIDA REAL si se nombra HORA',
    notActualOutTimeButDate: 'HORA DE SALIDA REAL si se nombra FECHA'
  },
  wo: {
    notDelivered: 'No entregado todavía',
    createSuccessfully: 'pedido creado correctamente.',
    SavedSuccessfully: 'guardado correctamente.',
    memoSavedSuccessfully: 'memo guardado correctamente.',
    workOrder: 'Orden de trabajo',
    email: {
      puSchedule: {
        subject:
          '[{billTo}]Horario de la terminal, {containerNumber}, {pulloutLocation}, {pulloutSchedule}',
        body:
          'Estimado {billTo}, <br/> {containerNumber} está programado para recoger de {pulloutLocation} en {pulloutSchedule} <br/>NGL actualizará el horario lo antes posible <br/>Gracias,'
      },
    },
    deliverySchedule: {
      subject:
        'DILV APPT, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
      body: `Dear {billTo}, <br/>{containerNumber} has been scheduled to deliver to {deliveryLocation} at {deliverySchedule} <br/>NGL will update the schedule as soon as possible <br/>Thanks,`
    },
    podPol: {
      subject:
        '{type}, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
      body:
        'Estimado {billTo}, <br/> {containerNumber} ha sido entregado <br/>¡Por favor busque el archivo adjunto! <br/> NNGL actualizará el horario lo antes posible <br/>Gracias,'
    },
    demurrage: {
      subject:
        'Advertencia-Dmrgs {containerNumber}, {pulloutLocation}, {lastFreeDay}',
      body: `Estimado {billTo}: <br/>Para evitar cargos por demora, {containerNumber} debe ser retirado de una terminal antes del {lastFreeDay}. <br/>Por favor ayúdenos a borrar la retención lo antes posible para que podamos recuperar el contenedor. <br/>Si tiene preguntas, comuníquese con nosotros para que las respondamos de inmediato, ¡gracias! <br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber} <br/>Cliente: {customer} <br/>Último día libre: {lastFreeDay } <br/>`
    },
    waiting: {
      subject:
        'Advertencia-DrvWaitings {containerNumber}, {deliveryLocation}, {deliverySchedule}',
      body: `Estimado {billTo}: <br/>Tenga en cuenta que el conductor ha estado en el lugar desde el {deliverySchedule} y el tiempo de espera comenzará pronto. <br/>Si tiene preguntas, comuníquese con nosotros para que se las respondan de inmediato, ¡gracias! <br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber} <br/>Customer: {customer} <br/>Appointment Time: {deliverySchedule} `
    },
    perdiem: {
      subject: 'Advertencia-PrDms {containerNumber}, {returnLocation}, {returnFreeDay}',
      body: `Estimado {billTo}, <br/>Para evitar el cargo por día, {containerNumber} debe ser devuelto antes del {returnFreeDay}. <br/>Por favor, ayúdenos a devolver el contenedor en cuestión lo antes posible. <br/>Si tiene preguntas, comuníquese con nosotros para que las respondamos de inmediato, ¡gracias! <br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber} <br/>Cliente: {customer} <br/>Fecha de entrega: {deliverySchedule} <br/>Día de devolución: {returnFreeDay} `
    },
    trackingId: {
      subject:
        'ID de seguimiento para CONT# {containerNumber},{deliveryLocation}',
      body: `
      Estimado {billTo}:<br/>CONT# {containerNumber} ha salido a {deliveryLocation} a las {previousDeliverySchedule}<br/>Entrega a: {deliveryAddress}<br/>Container #:{containerNumber} <br/>PO # o REF #: {customer} <br/>Programa de entrega: {deliverySchedule} <br/><h1 style="text-align: center;"><a title="LIVE ETA" href="{trackingUrl}" target="_blank" rel="noopener">Contenedor de seguimiento</a></h1>
      Gracias,`
    }
  },
  schedule: {
    dateMoreMonth:
      'Schedule date is more than one month than the previous schedule date',
    pullOutLaterLFD: 'P/OUT Schedule date is later than LFD',
    scheduleInLaterRFD: 'Schedule date is later than RFD'
  },
  reports: {
    categories: 'categoría',
    div: 'div',
    search: 'fecha de búsqueda',
    searchFrom: 'desde',
    searchTo: 'to',
    billTo: 'facturar a',
    searchYear: 'Año',
    searchMonth: 'Mes'
  },
  userManagement: {
    deleteConfirmMessage: `Eliminar un usuario eliminará el acceso de ese usuario al sistema.\n\n
    ¿Está seguro de que desea eliminar este usuario?`,
    resetConfirmMessage: `¿Está seguro de que desea restablecer la contraseña de {name} \n\n    
    Las credenciales de inicio de sesión se enviarán a {email}`,
    userManagement: 'Gestión de usuarios',
    sendResetEmail: 'Enviar correo electrónico de restablecimiento',
    deleteUser: 'Borrar usuario',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    firstPage: 'Primera página',
    defaultDivision: 'División predeterminada',
    update: 'Actualizar',
    close: 'Cerrar',
    requiredFieldMessage: 'Faltan campos obligatorios',
    yes: 'Sí',
    status: 'Estado',
    filterBy: 'Filtrado por',
    userName: 'Nombre de usuario',
    reset: 'Reiniciar',
    create: 'Crear'
  },
  datePicker: {
    '90DaysDiff': `You've set a 90-day difference from the current one. Are you sure?`,
    incorrectDate: 'Please enter correct date in MM/DD/YY format',
    maxDate: 'From date must be early than To date',
    minDate: 'To date must be later than From date'
  }
};
