import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'division'
})
export default class DivisionModule extends VuexModule {
  defaultDivision: string = null;
  division: string = null;
  divisionsList: string[] = [];

  @Mutation
  public SET_DIVISION(division: string) {
    localStorage.setItem(`division`, division);
    this.division = division;
  }

  @Mutation
  public SET_DEFAULT_DIVISION(division: string) {
    this.defaultDivision = division;
  }

  @Mutation
  public SET_DIVISIONS_LIST(divisions: string[]) {
    this.divisionsList = divisions;
  }

  @Action
  setDivision(division: string) {
    this.context.commit('SET_DIVISION', division);
  }

  @Action
  setDefaultDivision(division: string) {
    this.context.commit('SET_DEFAULT_DIVISION', division);
  }

  @Action
  setDivisionsList(divisions: string[]) {
    this.context.commit('SET_DIVISIONS_LIST', divisions);
  }

  @Action
  cleanDivisions() {
    this.context.commit('SET_DIVISION', null);
    this.context.commit('SET_DIVISIONS_LIST', []);
    this.context.commit('SET_DEFAULT_DIVISION', null);
    localStorage.removeItem('division');
  }
}
