






















































import { FileUploadService } from '@/shared/services/fileUpload.service';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FileUpload extends Vue {
  @Prop({ default: null }) state: any;
  @Prop() buttonOnly: boolean;
  @Prop({ default: false }) notEmpty: boolean;
  @Prop({ default: '' }) placeholder: string;
  @Prop({ default: null }) updating: any;
  @Prop({ default: null }) url: any;
  @Prop({ default: false }) showDeleteFile: boolean;
  @Prop({ default: null }) label: string;
  @Prop({ default: false }) resetModel: boolean;
  @Prop({ default: false }) required: boolean;
  @Prop({ default: true }) showTrashButton: boolean;

  file: File = null;
  emptyFileExisist = false;

  constructor() {
    super();
  }

  async onFileChange(event: any) {
    const file = event.target.files[0];

    const { url, filename } = await FileUploadService.getUrl(file.name);

    if (!file && this.notEmpty) {
      this.file = null;
      this.emptyFileExisist = true;
    } else {
      this.emptyFileExisist = false;
      this.$emit('input', {
        file,
        filename: file.name,
        body: '',
        tempS3PreSignedUrl: url,
        tempS3FileName: filename
      });

      if (this.resetModel) {
        this.file = null;
      }
    }
  }

  clearFile() {
    (this.$refs as any).loadedFile.reset();
    this.file = null;
    this.$emit('input', this.file);
  }
}
