export const en = {
  action: {},
  searchBtn: 'Search',
  excelBtn: 'excel',
  emailBtn: 'email',
  cancelBtn: 'Cancel',
  navigation: {
    order: {
      title: 'order'
    },
    dispatch: {
      title: 'dispatch',
      book: 'booking',
      unavailable: 'una',
      available: 'ava',
      delivery: 'dlv',
      return: 'rtn'
    },
    main: {
      main: 'MAIN',
      import: 'IMPORT',
      export: 'EXPORT',
      van: 'VAN',
      cytocy: 'CY to CY',
      bareChassis: 'BARE CHASSIS'
    },
    reports: {
      report: 'Report',
      pullout: 'PULLOUT',
      delivery: 'DELIVERY',
      return: 'RETURN',
      demurrage: 'DEMURRAGE',
      perdiem: 'PERDIEM',
      statistics: 'STATISTICS',
      'early-warning': 'EARLY WARNING LIST'
    },
    driver: {
      title: 'DRIVER',
      pod: 'POD LIST',
      bill: 'BILL CONFIRM',
      manifest: 'MANIFEST'
    },
    map: {
      title: 'map',
      map: 'map',
      geofence: 'geofence'
    },
    master: {
      title: 'master',
      driver: 'driver',
      company: 'company',
      account: 'account code'
    },
    user: {
      user: 'User',
      profile: 'Profile',
      createUser: 'Create User',
      signOut: 'Sign Out'
    },
    import: {
      import: 'Import',
      order: 'ORDER',
      main: 'Main',
      dispatch: 'Dispatch'
    },
    export: {
      booking: 'Booking',
      dataInput: 'Data input',
      main: 'Main',
      dispatch: 'Dispatch',
      export: 'Export'
    },

    language: 'English'
  },
  order: {
    billTo: 'Bill To',
    mbl: 'MBL #',
    houseBL: 'House BL #',
    referenceNumber: 'REF #',
    poNumber: 'PO #',
    deliveryNumber: 'Delivery #',
    cont: 'Cont #',
    trailNumber: 'Trailer #',
    containerSize: 'Size',
    containerType: 'Type',
    vessel: 'VESSEL',
    chassisNumber: 'Chassis #',
    pulloutChassisNumber: 'P/OUT Chassis #',
    chassisPool: 'POOL',
    vesselEta: 'ETA',
    ava: 'AVA',
    dsc: 'DSC',
    obl: 'OBL',
    cus: 'CUS',
    oth: 'OTH',
    lfd: 'LFD',
    freeDays: 'Free Days',
    rfd: 'RFD',
    seal: 'SEAL',
    commodity: 'COMMODITY',
    weight: 'WGT',
    quantity: 'QTY',
    unit: 'Unit',
    sendTo: 'Send To',
    remarks: 'Remark',
    pullOut: 'PULLOUT',
    puSchedule: 'P/OUT Schedule',
    delivery: 'Delivery',
    deliverySchedule: 'DILV Schedule',
    return: 'Return',
    returnSchedule: 'RTN Schedule',
    oh: 'O/H',
    ow: 'O/W',
    haz: 'HAZ',
    hot: 'HOT',
    qty: 'Quantity',
    amt: 'Amount',
    rate: 'Rate',
    arName: 'A/R Name',
    arCode: 'A/R Code',
    ssl: 'SSL',
    dropLive: 'DROP/LIVE',
    bondEntry: 'Bond Entry #',
    do: 'DO',
    cf: 'CF',
    other: 'Other',
    categories: 'Categories',
    bookingNumber: 'Booking #',
    wms: 'WMS',
    erd: 'ERD',
    cutOff: 'CUT OFF',
    bk: 'BK#',
    avaDsc: 'AVAILABILITY DSC',
    avaDate: 'AVAILABILITY DATE',
    tare: 'TARE(lb)',
    st: 'S/T',
    mblBk: 'MBL / BK #',
    loadCFM: 'Load CFM',
    emptyCFM: 'Empty CFM'
  },
  common: {
    email: 'Email address',
    close: 'Close'
  },
  validations: {
    required: 'This is a required field.',
    max: 'Maximum count of characters {number}',
    maxIntegers: 'Maximum count of characters {number} and only integers',
    maxWithDecimal:
      'Maximum count of characters {number} and only 2 digits after the dot',
    reqMax: 'This is a required field and maximum count of characters {number}',
    lettersAndDigitsReq:
      'This field is a required and must have {letters} letters and {digits} digits',
    lettersAndDigitsOpt:
      'Field must have {letters} letters and {digits} digits',
    isValidPoandDlv: 'P/OUT Schedule and DILV Schedule fields are required.',
    invalidPO: 'P/OUT Schedule field are required.',
    invalidDLV: 'DILV Schedule field are required.',
    invalidReturn:
      'The RTN Schedule date and time must be later than P/OUT Schedule',
    laterThenMsg: 'The {current} date and time must be later than {related}',
    bcScheduleInvalidLocation:
      'The LOCA ID must be same to {locationField} field in row {row}',
    bcScheduleInvalidDelivery:
      'The LOCA ID must be same to {locationField} field in schedule',
    bcScheduleRequiredInRow: 'are required in row',
    bcScheduleFields: 'The fields',
    notEmptyFile: 'The file cannot be empty',
    rowsExpected: 'At least 2 rows in schedule are expected.',
    phoneFormat: 'Phone format: (###) ###-####',
    shouldNotBeEarlier: '{current} should not be earlier than {related}',
    conf: {
      nameDuplicated:
        'This {name} is duplicated. Please insert the different POOL for this one.',
      abbrDuplicated:
        'This {abbr} is duplicated. Please insert the different ABBR for this one.'
    }
  },
  contextMenu: {
    detailInfo: 'Detail info',
    billingInfo: 'Billing info',
    document: 'Document',
    tracking: 'Tracking',
    basicInfo: 'Basic info',
    location: 'Location',
    availability: 'Availability',
    schedule: 'Schedule',
    memo: 'Memo'
  },
  scheduleTable: {
    locationId: 'LOCA ID',
    locationInfo: 'LOCATION INFO',
    city: 'City',
    state: 'ST',
    in: 'IN',
    out: 'OUT',
    scheduleDate: 'SCHE DATE',
    time: 'Time',
    actualDate: 'ACTUAL DATE',
    memo: 'MEMO',
    driverId: 'DRV ID',
    status: 'STATUS',
    updatedBy: 'Updated by',
    notActualInDateButTime: 'ACTUAL IN DATE if TIME is named',
    notActualInTimeButDate: 'ACTUAL IN TIME if DATE is named',
    notActualOutDateButTime: 'ACTUAL OUT DATE if TIME is named',
    notActualOutTimeButDate: 'ACTUAL OUT TIME if DATE is named'
  },
  wo: {
    notDelivered: 'Not delivered yet',
    createSuccessfully: 'order successfully created.',
    savedSuccessfully: 'successfully saved.',
    memoSavedSuccessfully: 'memo successfully saved.',
    workOrder: 'Work Order',
    email: {
      puSchedule: {
        subject:
          '[{billTo}]Terminal Schedule, {containerNumber}, {pulloutLocation}, {pulloutSchedule}',
        body:
          'Dear {billTo}, <br/>{containerNumber} is scheduled to pick up from {pulloutLocation} at {pulloutSchedule} <br/> NGL will update the schedule as soon as possible <br/> Thanks,'
      },
      deliverySchedule: {
        subject:
          'DILV APPT, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
        body: `Dear {billTo}, <br/>{containerNumber} has been scheduled to deliver to {deliveryLocation} at {deliverySchedule} <br/><br/>NGL will update the schedule as soon as possible <br/><br/>Thanks,`
      },
      podPol: {
        subject:
          '{type}, {containerNumber}, {deliveryLocation}, {deliverySchedule}',
        body:
          'Dear {billTo}, <br/>{containerNumber} has been delivered <br/>Please find the attachment!<br/>NGL will update the schedule as soon as possible <br/>Thanks,'
      },
      demurrage: {
        subject:
          'Warning-Dmrgs {containerNumber}, {pulloutLocation}, {lastFreeDay}',
        body: `Dear {billTo},<br/>To avoid Demurrage charge, {containerNumber} must be pulled out of a terminal by {lastFreeDay}.<br/>Please assist us with getting the hold cleared as soon as possible so that we can retrieve the container.<br/>If you have questions, please contact us to have them answered promptly, thank you!<br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber}<br/>Customer: {customer}<br/>Last Free Day: {lastFreeDay}<br/>`
      },
      waiting: {
        subject:
          'Warning-DrvWaitings {containerNumber}, {deliveryLocation}, {deliverySchedule}',
        body: `Dear {billTo}, <br/>Please be advised that the driver has been on site since {deliverySchedule} and waiting time will start soon.<br/>If you have questions, please contact us to have them answered promptly, thank you!<br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber}<br/>Customer: {customer}<br/>Appointment Time:  {deliverySchedule}`
      },
      perdiem: {
        subject:
          'Warning-PrDms {containerNumber}, {returnLocation}, {returnFreeDay}',
        body: `Dear {billTo}, <br/>To avoid Per Diem charge, {containerNumber} must be returned by {returnFreeDay}. <br/>Please assist us with returning the subject container as soon as possible. <br/>If you have questions, please contact us to have them answered promptly, thank you!<br/>NGL WO #: {orderNumber} <br/>Container #: {containerNumber}<br/>Customer: {customer}<br/>Delivery date: {deliverySchedule} <br/>Return Free Day: {returnFreeDay}`
      },
      trackingId: {
        subject: 'Tracking ID for CONT# {containerNumber},{deliveryLocation}',
        body: `
        Dear {billTo},<br/>CONT# {containerNumber} has been departed to {deliveryLocation} at {previousDeliverySchedule}<br/>Delivery To: {deliveryAddress}<br/>Container #:{containerNumber} <br/>PO # or REF #: {customer}  <br/>Delivery Schedule: {deliverySchedule} <br/><h1 style="text-align: center;"><a title="LIVE ETA" href="{trackingUrl}" target="_blank" rel="noopener">Track Container</a></h1>
        Thanks,`
      }
    },
    schedule: {
      dateMoreMonth:
        'Schedule date is more than one month than the previous schedule date',
      pullOutLaterLFD: 'P/OUT Schedule date is later than LFD',
      scheduleInLaterRFD: 'Schedule date is later than RFD'
    }
  },
  reports: {
    categories: 'category',
    div: 'div',
    search: 'search date',
    searchFrom: 'from',
    searchTo: 'to',
    billTo: 'bill to',
    searchYear: 'Year',
    searchMonth: 'Month'
  },
  datePicker: {
    '90DaysDiff': `You've set a 90-day difference from the current one. Are you sure?`,
    incorrectDate: 'Please enter correct date in MM/DD/YY format',
    maxDate: 'From date must be early than To date',
    minDate: 'To date must be later than From date'
  },
  userManagement: {
    deleteConfirmMessage: `Deleting a user will remove that user's access to system.\n
    Are you sure you want to delete this user?`,
    resetConfirmMessage: `Are you sure want to reset the password for {name} \n\n
    Login credentials will be sent to {email}`,
    userManagement: 'User Management',
    sendResetEmail: 'Send Reset Email',
    deleteUser: 'Delete User',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phone: 'Phone',
    firstPage: 'First Page',
    defaultDivision: 'Default Division',
    update: 'Update',
    close: 'Close',
    requiredFieldMessage: 'Required fields are missing',
    yes: 'Yes',
    status: 'Status',
    filterBy: 'Filter By',
    userName: 'User Name',
    reset: 'Reset',
    create: 'Create'
  },
  tracking: {
    GATE_IN: 'Gate In',
    GATE_OUT: 'Gate Out',
    READY_TO_START: 'Ready to start',
    READY_TO_PICK_UP: 'Ready to pick up',
    PICKED_UP: 'Picked up',
    READY_TO_DROP_OFF: 'Ready to drop off',
    LOADING_UNLOADING: 'Loading unloading',
    LOADED_UNLOADED: 'Loading unloaded',
    RETURN: 'Return'
  },
  trackingStatus: {
    COMPLETED: 'Completed',
    IN_TRANSIT: 'In transit'
  }
};
