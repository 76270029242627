
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import {
  BS_DATEPICKER_WITH_TIME,
  DATE_API_FORMAT,
  DATE_API_FORMAT_WITHOUT_TIME
} from '@/utils/date.util';

@Component({})
export default class DateTimePicker extends Vue {
  @Prop(String) min: string;
  @Prop(Boolean) state: boolean;
  @Prop(Boolean) requiredControlsValidation: boolean;
  @Prop(String) minTime: string;
  @Prop(Boolean) isDirty: boolean;
  @Prop(Boolean) onlyDate: boolean;
  @Prop(String) relatedControlName: string;
  @Prop(String) currentControlName: string;
  @Prop(Boolean) validateRelatedTime: boolean;
  @Prop(String) value: string;
  @Prop(String) errorMessage: string;
  @Prop(String) relatedControlValue: string;
  @Prop(Boolean) isCorrectFortmat: string;

  dateModel: any = null;
  timeModel: any = null;
  inputDiffMinutes: any = null;

  dateInputModel: string = null;
  datePickerModel: string = null;
  needToUpdateDateValue = true;

  constructor() {
    super();
  }

  onTimeChange() {
    if (this.dateModel) {
      this.setModelValue();
    }
  }

  @Watch('min')
  onMinDateChanged(minDate: string) {
    this.validateFieldsByMinDate(
      this.dateModel,
      this.timeModel,
      minDate,
      this.minTime
    );
  }

  @Watch('minTime')
  onTimeChanged(minTime: string) {
    this.validateFieldsByMinDate(
      this.dateModel,
      this.timeModel,
      this.min,
      minTime
    );
  }

  @Watch('value')
  onValueChanged(value: string) {
    const trimedValue: string = value?.trim() || '';
    if (
      trimedValue &&
      trimedValue.length === 14 &&
      !isNaN(Number(trimedValue))
    ) {
      this.dateModel = value;
      this.timeModel = moment(value, DATE_API_FORMAT).format('HH:mm');
    } else {
      this.dateModel = '';
      this.timeModel = '';
    }
  }

  validateFieldsByMinDate(dateModel, timeModel, minDate, minTime) {
    const a = moment(`${minDate} ${minTime}`, BS_DATEPICKER_WITH_TIME);
    const b = moment(`${dateModel} ${timeModel}`, BS_DATEPICKER_WITH_TIME);
    this.inputDiffMinutes = b.diff(a, 'minutes');

    if (
      this.isRelatedValidation() &&
      ((this.isDifferentTime() && this.isDirty) ||
        (isNaN(this.inputDiffMinutes) && this.isDirty))
    ) {
      this.$emit('update', true);
    } else {
      this.$emit('update', false);
    }
  }

  isDifferentTime() {
    return this.inputDiffMinutes <= 0;
  }

  isRelatedValidation() {
    return this.relatedControlValue && this.dateModel ? true : false;
  }

  // inputChange() {
  //   this.dateModel = moment(this.dateInputModel, DATE_VIEW_FORMAT).format(
  //     BS_DATEPICKER_FORMAT
  //   );

  //   this.needToUpdateDateValue = false;
  //   this.setModelValue();
  // }

  datePickerChange() {
    // if (!this.needToUpdateDateValue) {
    //   this.needToUpdateDateValue = true;
    //   return;
    // }

    // this.dateInputModel = moment(this.dateModel, BS_DATEPICKER_FORMAT).format(
    //   DATE_VIEW_FORMAT
    // );

    this.setModelValue();
  }

  setModelValue() {
    if (this.dateModel === 'Invalid date') {
      this.dateModel = '';
    }

    this.$emit(
      'input',
      this.dateModel
        ? moment(
            `${moment(this.dateModel, DATE_API_FORMAT).format(
              DATE_API_FORMAT_WITHOUT_TIME
            )} ${this.timeModel ? this.timeModel : '00:00:00'}`,
            BS_DATEPICKER_WITH_TIME
          ).format(DATE_API_FORMAT)
        : ''
    );

    this.validateFieldsByMinDate(
      this.dateModel,
      this.timeModel,
      this.min,
      this.minTime
    );

    this.$forceUpdate();
  }
}
