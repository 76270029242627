import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'config'
})
export default class ConfigModule extends VuexModule {
  loading = false;
  configDivisions = null;
  configPool = null;
  configSize = null;
  configSSl = null;

  @Mutation
  public SET_LOADING_STATUS(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  public SET_CONFIG_DIVISIONS(payload) {
    this.configDivisions = payload ? { ...payload } : null;
  }

  @Mutation
  public SET_CONFIG_POOL(payload) {
    this.configPool = payload ? { ...payload } : null;
  }

  @Mutation
  public SET_CONFIG_SIZE(payload) {
    this.configSize = payload ? { ...payload } : null;
  }

  @Mutation
  public SET_CONFIG_SSL(payload) {
    this.configSSl = payload ? { ...payload } : null;
  }

  @Action
  async getConfigDivisions(payload) {
    // TODO work with admin service
  }

  @Action
  async getConfigPool(payload) {
    // TODO work with admin service
  }

  @Action
  async getConfigSize(payload) {
    // TODO work with admin service
  }

  @Action
  async getConfigSSL(payload) {
    // TODO work with admin service
  }
}
