









































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import UserMenu from './UserMenu.vue';
import LanguageMenu from './LanguageMenu.vue';

import { ReportsType } from '@/pages/Reports/models';
import { DriverTypes } from '@/pages/Driver/models/driver.model';
import { AccountingTypes } from '@/pages/Accounting/models/accounting.model';
import { DivisionModule } from '@/store/index';
import MenuConfig from './config/menu.json';

@Component({
  components: { UserMenu, LanguageMenu, Fragment }
})
export default class Header extends Vue {
  divisionModule = DivisionModule;

  menuConfig = MenuConfig;

  moduleChunks = [
    { key: 'Portal', to: '' },
    { key: 'YMS', to: '/#/yms/daily-in-out', external: true },
    { key: 'WMS', to: '/master/company' },
    { key: 'FMS', to: '/master/account-code' },
    { key: 'CAM', to: '/master/account-code' }
  ];

  adminChunks = [
    { key: 'Basic', to: '' },
    { key: 'Admin Auth', to: '/admin/roles' },
    { key: 'Conf', to: '/admin/config' },
    { key: 'Early warning', to: '/admin/early-warning' },
    {
      key: 'Early warning modal',
      cb: () => {
        const routeData = this.$router.resolve({ name: 'early-warning-modal' });
        window.open(routeData.href, 'EarlyWindow', 'height=640,width=1340');
      }
    },
    { key: 'User management', to: '/admin/user-managment' }
  ];

  ymsChunks = [
    { key: 'Daily in/out', to: '/yms/daily-in-out', name: 'daily-in-out' },
    { key: 'Inventory', to: '/yms/inventory', name: 'inventory' },
    { key: 'Damage report', to: '/yms/damage-report', name: 'damage' }
  ];

  get page() {
    return this.$route.meta.subPage || 'main';
  }

  get hideHeaedr() {
    return this.$route.meta.hideHeader === true;
  }

  openEarlyWarningModal() {
    console.log('asdsd');
  }

  updateDivision(division: string) {
    this.divisionModule.setDivision(division);
    window.location.reload();
  }
}
