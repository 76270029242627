












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Breadcrumbs extends Vue {
  @Prop({ default: 12 }) perPage: number;
  @Prop() rows: number;

  currentPage = this.$route.query.page || 1;

  constructor() {
    super();
  }

  updatePage() {
    this.$router.push({
      query: { ...this.$route.query, page: `${this.currentPage}` }
    });
  }
}
