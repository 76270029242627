import Accounting from '@/store/modules/accounting/accountig.module';
import Company from '@/store/modules/company/company.module';
import Config from '@/store/modules/config/config.module';
import Dispatch from '@/store/modules/dispatch/dispatch.module';
import Division from '@/store/modules/division/division.module';
import MasterDriver from '@/store/modules/master-driver/master-driver.module';
import Employee from '@/store/modules/employee/employee.module';
import Account from '@/store/modules/masterAccount/account.module';
import Driver from '@/store/modules/driver/driver.module';
import Reports from '@/store/modules/reports/reports.module';
import User from '@/store/modules/user/user.module';
import UserManagement from '@/store/modules/userManagement/user-management.module';
import WorkOrderList from '@/store/modules/workOrder/work-order-list.module';
import WorkOrder from '@/store/modules/workOrder/work-order.module';
import Yms from '@/store/modules/yms/yms.module';
import Vue from 'vue';
import Vuex from 'vuex';
import { getModule } from 'vuex-module-decorators';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {}
});

store.registerModule('config', Config);
store.registerModule('user', User);
store.registerModule('workOrder', WorkOrder);
store.registerModule('workOrderList', WorkOrderList);
store.registerModule('masterDriver', MasterDriver);
store.registerModule('employee', Employee);
store.registerModule('reports', Reports);
store.registerModule('company', Company);
store.registerModule('account', Account);
store.registerModule('dispatch', Dispatch);
store.registerModule('accounting', Accounting);
store.registerModule('driver', Driver);
store.registerModule('division', Division);
store.registerModule('yms', Yms);
store.registerModule('userManagement', UserManagement);

export default store;

export const ConfigModule = getModule(Config, store);
export const UserModule = getModule(User, store);
export const WorkOrderModule = getModule(WorkOrder, store);
export const WorkOrderListModule = getModule(WorkOrderList, store);
export const MasterDriverModule = getModule(MasterDriver, store);
export const EmployeeModule = getModule(Employee, store);
export const ReportsModule = getModule(Reports, store);
export const CompanyModule = getModule(Company, store);
export const AccountModule = getModule(Account, store);
export const DispatchModule = getModule(Dispatch, store);
export const AccountingModule = getModule(Accounting, store);
export const DriverModule = getModule(Driver, store);
export const DivisionModule = getModule(Division, store);
export const YmsModule = getModule(Yms, store);
export const UserManagementModule = getModule(UserManagement, store);
