export const TmsShowMenuOnHover = {
  bind,
  unbind
};

const ADD_REF_DROPDOWN_TO_ELEMENT =
  'add ref="dropdown" to the element && and id';
const onHoverHandlerSymbol = Symbol('tmsShowMenuOnHoverHandler');
const onLeaveHandlerSymbol = Symbol('tmsShowMenuOnLeaveHandler');

function bind(element: HTMLElement, bindings, vnode) {
  element.addEventListener('mouseenter', onHover);
  element.addEventListener('mouseleave', onLeave);
  element[onHoverHandlerSymbol] = onHover;
  element[onLeaveHandlerSymbol] = onLeave;

  function onHover() {
    const dropdown = vnode.context.$refs[element.id][0];
    if (dropdown) {
      dropdown.show();
    } else {
      console.error(ADD_REF_DROPDOWN_TO_ELEMENT);
    }
  }

  function onLeave() {
    const dropdown = vnode.context.$refs[element.id][0];
    if (dropdown) {
      dropdown.hide();
    } else {
      console.error(ADD_REF_DROPDOWN_TO_ELEMENT);
    }
  }
}

function unbind(element: HTMLElement) {
  element.removeEventListener('mouseenter', element[onHoverHandlerSymbol]);
  element.removeEventListener('mouseleave', element[onLeaveHandlerSymbol]);
  element[onHoverHandlerSymbol] = null;
  element[onLeaveHandlerSymbol] = null;
}
