



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';
import { SelectModel } from '@/shared/models/generic.model';
import { WorkOrderModule } from '@/store/index';

import moment from 'moment';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { MasterDataService } from '@/shared/services/mater-data/master-data.service';
import WoMemo from './WoMemo.vue';

import {
  addDaysToDate,
  BS_DATEPICKER_FORMAT,
  convertAPIToDateFormat,
  convertDateToFormat,
  convertStrignDateToDate,
  DATE_API_FORMAT,
  isWeekend,
  lessThenCompare,
  lessThenTimeCompare
} from '@/utils/date.util';

import { woModels, WoType, woModelsValidation } from './models/index';
import {
  FieldChangeEventBus,
  FildChangeEvent
} from '@/shared/services/field-change-event-bus';
import { WOFieldChangeEventBusInstance } from './wo-field-change-event-bus';
import { isHoliday } from '@/shared/services/holidays';
import { DivisionModule } from '@/store/index';
import { LocationType } from '@/shared/models/woSchedule.model';
import { DispatchService } from '@/shared/services/dispatch/dispatch.service';
import { Booking } from '@/shared/services/dispatch/booking.model';
import { DispatchType } from '@/pages/Dispatch/models/dispatch.model';
import {
  FilterConditions,
  FilterInput,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { MainOrderType } from '@/pages/Main/filters/PropertyFilter/property-filter.model';
import { containerSize, containerType, shippingLine } from '@/shared/mocks/wo';
import { ToastHelper } from '@/utils/toast.util';

Component.registerHooks(['validations']);

@Component({
  mixins: [validationMixin],
  components: {
    WoMemo
  }
})
export default class WorkOrderForm extends Vue {
  @Prop({ default: WoType.ImportRegular }) woType!: WoType;
  @Prop({ default: false }) edit: boolean;
  @Prop({ default: null }) wo: any;
  @Prop({ default: false }) earlyWarning: boolean;
  @Prop({ default: () => [{ billTo: 1 }, { ETA: 2 }] })
  earlyWarningFields: any[];

  hideContainerData = false;

  @Watch('$route')
  onRouterChange(route) {
    this.categoryChange(route.params.type);
  }

  @Watch('containerSizes')
  onContainerSizes(list) {
    if (
      this.edit &&
      (this.woType === WoType.ExportRegular ||
        this.woType === WoType.ExportShuttle) &&
      !this.bookingIdData
    ) {
      this.hideContainerData = true;
      return;
    }

    this.hideContainerData = false;

    // if (this.bookingIdData && list.length) {
    //   this.form.containerSize = list[0].value;
    // }
  }

  @Watch('containerTypes')
  onContainerTypes(list) {
    if (
      this.edit &&
      (this.woType === WoType.ExportRegular ||
        this.woType === WoType.ExportShuttle) &&
      !this.bookingIdData
    ) {
      this.hideContainerData = true;
      return;
    }

    this.hideContainerData = false;

    // if (this.bookingIdData && list.length) {
    //   this.form.containerType = list[0].value;
    // }
  }

  renderComponent = false;

  workOrderModule = WorkOrderModule;

  companyService = CompanyService;
  masterDataService = MasterDataService;
  dispatchService = DispatchService;
  models = woModels as any;

  errorModal = false;

  form: any = new this.models[this.woType]();

  WoType = WoType;

  woTypeModel: WoType = this.woType;

  bookingIdData = null;

  selectValues: {
    containerType?: SelectModel[];
    containerSize?: SelectModel[];
    chassisPool?: SelectModel[];
    shippingLine?: SelectModel[];
  } = {};

  unitOptions = [
    { value: 'Piece', text: 'Piece' },
    { value: 'Carton', text: 'Carton' },
    { value: 'Pallet', text: 'Pallet' },
    { value: 'Unit', text: 'Unit' }
  ];

  loadEmpty = [
    { value: 'Load', text: 'Load' },
    { value: 'Empty', text: 'Empty' }
  ];

  dropLive = [
    { value: 'DROP', text: 'DROP' },
    { value: 'LIVE', text: 'LIVE' }
  ];

  freeDays = [
    { value: 'Working', text: 'Working' },
    { value: 'Calendar', text: 'Calendar' }
  ];

  maxDate = moment().format();

  deliveryScheduleMinDate = null;
  deliveryScheduleMinTime = null;
  returnScheduleMinDate = null;
  returnScheduleMinTime = null;

  moment = moment;

  categories = [
    { value: WoType.ImportRegular, text: 'IMP - REG' },
    { value: WoType.ImportShuttle, text: 'IMP - SHUTTLE' },
    { value: WoType.ExportRegular, text: 'EXP - REG' },
    { value: WoType.ExportShuttle, text: 'EXP - SHUTTLE' },
    { value: WoType.VanRegular, text: 'VAN' },
    { value: WoType.CyToCy, text: 'CY TO CY' },
    { value: WoType.BareChassis, text: 'BARE CHASSIS' }
  ];

  tableData = [];
  scheduleIsValidStates = new Map<LocationType, boolean>();
  allAvaBoxesChecked = null;
  private bookingSizeKeyTypeValueMap: Map<string, Set<string>> = new Map();

  private subscriptions: Function[] = [];
  private fieldChangeEventBus: FieldChangeEventBus = WOFieldChangeEventBusInstance;
  private pulloutScheduleDate: string;
  private divisionModule = DivisionModule;
  minCutOff = null;

  initialBookingId = '';

  constructor() {
    super();
    // this.initForm();
  }

  get requiredFields(): Set<string> {
    const woValidation = woModelsValidation[this.woTypeModel];

    return new Set(
      Object.keys(woValidation).filter(
        fieldName => woValidation[fieldName].required
      )
    );
  }

  get containerSizes() {
    if (this.selectValues?.containerSize) {
      if (
        this.form.bookingNumber &&
        this.bookingIdData &&
        this.bookingIdData.containers.length
      ) {
        return this.bookingIdData.containers.map(container => {
          const selected = containerSize.find(
            size => size.id == container.size
          );
          return {
            value: selected.id,
            text: selected.code,
            disabled:
              container.total === container.used &&
              (!this.wo || this.wo.containerSize !== selected.id)
          };
        });
      } else {
        return this.selectValues?.containerSize;
      }
    } else {
      return [];
    }
  }

  get containerTypes() {
    if (this.selectValues?.containerType) {
      if (
        this.form.bookingNumber &&
        this.bookingIdData &&
        this.bookingIdData.containers.length
      ) {
        return (
          this.bookingIdData.containers
            // .filter(container => )
            .map(container => {
              const selected = containerType.find(
                item => item.id == container.type
              );

              return {
                value: selected.id,
                text: selected.code,
                size: container.size,
                disabled:
                  container.total === container.used &&
                  (!this.wo || this.wo.containerType !== selected.id)
              };
            })
        );
      } else {
        return this.selectValues?.containerType;
      }
    } else {
      return [];
    }
  }

  get pulloutScheduleError() {
    if (
      this.validateState('pulloutSchedule') === null ||
      this.validateState('pulloutSchedule')
    ) {
      return null;
    }

    if (woModelsValidation[this.woTypeModel].pulloutSchedule.required) {
      return this.$t('validations.required');
    }

    return null;
  }

  get deliveryScheduleError() {
    if (
      this.validateState('deliverySchedule') === null ||
      this.validateState('deliverySchedule')
    ) {
      return null;
    }

    if (woModelsValidation[this.woTypeModel].pulloutSchedule.required) {
      return this.$t('validations.required');
    }

    return null;
  }

  created() {
    this.initialBookingId = this.wo?.bookingNumber;
    this.initForm();
  }

  async initForm() {
    this.initModel();
    await this.getSelectValues();
    this.renderComponent = true;
  }

  initModel() {
    if (this.wo) {
      const dateKeys = [
        'eta',
        'lastFreeDay',
        'avaDscDate',
        'avaCusDate',
        'avaOthDate',
        'avaOblDate',
        'avaDate',
        'returnFreeDay',
        'erd',
        'streetTurn',
        'cutOff'
      ];

      dateKeys.forEach(key => {
        if (this.wo[key]) {
          this.wo[key] = convertAPIToDateFormat(this.wo[key]);
        }
      });

      Object.keys(this.wo).forEach(
        k => this.wo[k] == null && delete this.wo[k]
      );

      Object.keys(this.wo).forEach(key => {
        this.form[key] = this.wo[key];
      });

      if (
        this.woType === WoType.ExportRegular ||
        this.woType === WoType.ExportShuttle
      ) {
        this.searchBooks(this.form.bookingNumber).then(res => {
          const booking = res.find(item => item.id === this.form.bookingNumber);
          this.bookingChange(booking);
        });
      }
    }
  }

  onlyNumber($event) {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
      $event.preventDefault();
    }
  }

  onFreeDaysChange() {
    this.calculateRFD();
  }

  onFreeDaysTypeChange() {
    this.calculateRFD();
  }

  onCutOffErdChange() {
    if (this.form.cutOff && this.form.erd) {
      this.minCutOff = convertAPIToDateFormat(this.form.erd);
      const inputDiffMinutes = lessThenCompare(this.form.cutOff, this.form.erd);
      return inputDiffMinutes >= 0 ? true : false;
    }
  }

  setLFDandRFDColor(date, type) {
    let color = '';

    const pullOutIndex = this.form?.schedules?.findIndex(
      row => row.locationType === LocationType.PULLOUT
    );
    const returnIndex = this.form?.schedules?.findIndex(
      row => row.locationType === LocationType.RETURN
    );

    const datePickerColor = diff => {
      if (isNaN(diff)) {
        return;
      }

      if (diff === 0 || diff === 1) {
        color = 'c-red';
        return color;
      }

      if (diff < 0) {
        color = 'c-purple';
        return color;
      }
    };

    const diff = lessThenCompare(date, moment().startOf('day'));
    if (
      (type === 'LFD' && !this.form.schedules[pullOutIndex]?.actualIn) ||
      (type === 'RFD' && !this.form.schedules[returnIndex]?.actualIn)
    ) {
      return (color = datePickerColor(diff));
    }
  }

  containerSizeSelect($event) {
    const type = this.containerTypes.find(i => i.value === $event);
    if (type.size) {
      this.form.containerSize = type.size;
    }
  }

  containerTypeSelect($event) {
    const type = this.containerTypes.find(i => i.value === $event);
    if (type.size) {
      this.form.containerSize = type.size;
    }
  }

  async getSelectValues() {
    this.selectValues = await WorkOrderService.getSelectValues();
  }

  updateBKMemo(memo) {
    this.form.bookingNumberMemo = memo;
  }

  categoryChange(key: WoType) {
    this.woTypeModel = key;

    if (this.$v && this.$v.form) {
      this.$v.form.$reset();
    }

    if (this.$route.params.type !== key) {
      this.$router.push({ name: 'workOrder', params: { type: `${key}` } });
    }
    this.bookingSizeKeyTypeValueMap = new Map();
    this.resetForm();
  }

  checkFormKey(key) {
    return Object.prototype.hasOwnProperty.call(this.form, key);
  }

  updateStreetTurn(value) {
    this.wo.streetTurn = value;
    this.onFormChanged(this.form);
    this.$forceUpdate();

    if (
      [WoType.ExportShuttle, WoType.ExportRegular].indexOf(this.woTypeModel) !==
      -1
    ) {
      this.calculateRFD();
    }
  }

  async submitReset() {
    const typeaheadRefs = [
      (this.$refs as any).billToRef,
      (this.$refs as any).pullLocRef,
      (this.$refs as any).deliveryLocRef,
      (this.$refs as any).returnLocRef
    ];

    const filesRefs = [
      (this.$refs as any).fileUploadDoRef,
      (this.$refs as any).fileUploadCfRef,
      (this.$refs as any).fileUploadOtherRef
    ];

    const defaultOrderFields = [
      'containerSize',
      'containerType',
      'chassisPool'
    ];

    const mappedFiles = filesRefs.filter(el => el !== undefined);

    if (this.isFormInValid()) {
      return;
    } else {
      if (this.isIncorrectFormatDatepicker() || !this.isValidSchedules()) {
        return;
      }
    }

    const data = this.getFormValue();
    if (await this.createWO(data, this.woTypeModel)) {
      typeaheadRefs.forEach(el => (el.$refs.typeahead.inputValue = ''));
      mappedFiles.forEach(el => el.clearFile());
      this.resetForm();
      await this.initForm();
      this.$v.$reset();
    }
  }

  async submitCopy() {
    if (this.isFormInValid()) {
      return;
    } else {
      if (this.isIncorrectFormatDatepicker() || !this.isValidSchedules()) {
        return;
      }
    }

    const data = this.getFormValue();

    if (await this.createWO(data, this.woTypeModel)) {
      this.woTypeModel !== WoType.VanRegular
        ? (this.form.containerNumber = '')
        : (this.form.trailNumber = '');

      if (
        this.woTypeModel == WoType.ExportRegular ||
        this.woTypeModel == WoType.ExportShuttle
      ) {
        this.form.containerSize = '';
        this.form.containerType = '';
      }
    }
  }

  async createWO(data: any, woType: any) {
    const successMsg = this.$i18n.t('wo.createSuccessfully');

    if (this.divisionModule.division) {
      data = { ...data, division: this.divisionModule.division };
    }

    return await this.workOrderModule.createWO({ data, woType, successMsg });
  }

  async searchBooks(startWith: string) {
    const filters = [
      new FilterInput({
        condition: FilterConditions.BeginsWith,
        search: startWith,
        field: 'id'
      }),
      new FilterInput({
        condition: FilterConditions.Equal,
        search: true,
        field: 'active'
      }),
      new FilterInput({
        condition: FilterConditions.Equal,
        search: DivisionModule.division,
        field: 'division'
      })
    ];

    if (this.woTypeModel == WoType.ImportRegular) {
      filters.push(
        new FilterInput({
          condition: FilterConditions.Equal,
          search: this.form.shippingLine,
          field: 'shippingLine'
        }),
        new FilterInput({
          condition: FilterConditions.Equal,
          search: this.form.containerSize,
          field: 'containerSize'
        }),
        new FilterInput({
          condition: FilterConditions.Equal,
          search: this.form.containerType,
          field: 'containerType'
        })
      );
    }

    const data = await this.dispatchService.searchRequest(
      DispatchType.EXP,
      'booking',
      TransformFiltersToJson(filters)
    );

    return data.data;
  }

  async bookingChange(booking: Booking) {
    const response = await this.dispatchService.getBookingById(booking.id);
    this.bookingIdData = response.data;

    if (booking && this.bookingIdData) {
      this.form.bookingNumber = booking.id;
      this.bookingSizeKeyTypeValueMap = new Map();
      this.addSizeTypeToBookingSizeKeyTypeValueMap(
        booking.containerSize1,
        booking.containerType1
      );
      this.addSizeTypeToBookingSizeKeyTypeValueMap(
        booking.containerSize2,
        booking.containerType2
      );
      this.addSizeTypeToBookingSizeKeyTypeValueMap(
        booking.containerSize3,
        booking.containerType3
      );

      const exceptedField = [
        'deliveryLocation',
        'deliveryLocationName',
        'pulloutLocation',
        'pulloutLocationName',
        'returnLocation',
        'returnLocationName'
      ];

      if (
        this.woTypeModel !== WoType.ExportRegular &&
        this.woTypeModel !== WoType.ExportShuttle
      ) {
        exceptedField.forEach(item => {
          delete this.bookingIdData[item];
        });
      }

      Object.keys(this.bookingIdData).forEach(key => {
        if (key in this.form && this.bookingIdData[key] !== null) {
          if (
            key == 'billTo' &&
            (this.woTypeModel == WoType.ImportRegular ||
              this.woTypeModel == WoType.ImportShuttle)
          ) {
            return;
          }
          this.form[key] = this.bookingIdData[key];
        }

        // TODO we use wo object and form object with the same fields
        // if (key in this.wo && bookingIdData.data[key] !== null) {
        //   this.wo[key] = bookingIdData.data[key];
        // }
      });

      if (
        this.woTypeModel == WoType.ExportRegular ||
        this.woTypeModel == WoType.ExportShuttle
      ) {
        this.$emit(
          'bookingChange',
          JSON.parse(JSON.stringify(this.bookingIdData))
        );
      }
    }

    // this.form.containerSize = this.containerSizes[0].value;
    // this.form.containerType = '';

    if (
      this.wo &&
      this.wo.containerSize &&
      this.woTypeModel !== WoType.ImportRegular
    ) {
      this.form.containerSize = this.wo.containerSize;
    } else {
      if (this.bookingSizeKeyTypeValueMap.size) {
        const freeContainers = this.containerSizes.filter(i => !i.disabled);

        if (freeContainers && freeContainers.length === 0) {
          ToastHelper.show(
            `Booking ${this.form.bookingNumber}`,
            'There are no Containers usable',
            8000,
            'danger'
          );

          (this.$refs as any).bookingNumberRef.query = '';
          this.$v.form.bookingNumber.$model = '';
          return;
        }

        const firstKey = freeContainers
          ? freeContainers[0].value
          : this.bookingSizeKeyTypeValueMap.keys().next().value;

        this.form.containerSize = firstKey;

        this.form.containerType = this.bookingSizeKeyTypeValueMap
          .get(firstKey)
          .values()
          .next().value;
      }
    }

    if (booking.shippingLine) {
      this.form.shippingLine = booking.shippingLine;
    }
  }

  bookingChangeDuringEditing(booking: Booking) {
    const formState = JSON.parse(JSON.stringify(this.form));
    formState.bookingNumber = booking?.id;
    this.form = { ...formState };

    this.wo.bookingNumber = booking?.id;
    this.onFormChanged(this.form);
    this.$forceUpdate();
  }

  onContainerSizeChange(value) {
    if (this.checkFormKey('bookingNumber')) {
      if (this.bookingSizeKeyTypeValueMap.get(value)?.size) {
        if (
          !this.bookingSizeKeyTypeValueMap
            .get(value)
            .has(this.form.containerType)
        ) {
          this.form.containerType = this.form.containerType = this.bookingSizeKeyTypeValueMap
            .get(value)
            .values()
            .next().value;
        }
      } else {
        this.form.containerType = '';
      }
    }
  }

  private addSizeTypeToBookingSizeKeyTypeValueMap(
    containerSize: string,
    containerType: string
  ) {
    if (!containerSize) {
      return;
    }

    let types: Set<string>;
    if (this.bookingSizeKeyTypeValueMap.has(containerSize)) {
      types = this.bookingSizeKeyTypeValueMap.get(containerSize);
    } else {
      types = new Set();
    }

    if (containerType) {
      types.add(containerType);
    }

    this.bookingSizeKeyTypeValueMap.set(containerSize, types);
  }

  resetForm() {
    const models = woModels as any;
    this.form = new models[this.woTypeModel]();
  }

  isFormInValid() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }

    return this.$v.form.$anyError;
  }

  getFormValue() {
    const data = {
      ...this.form,
      category: this.woTypeModel
    };
    return data;
  }

  validations() {
    return {
      form: woModelsValidation[this.woTypeModel]
    };
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  isChaissDirty(chassisNumber, chassisPool) {
    return this.isBothControlsValid(chassisNumber, chassisPool) === false
      ? 'd-block'
      : 'd-none';
  }

  isBothControlsValid(control, controlToCompare) {
    if (control && !controlToCompare) {
      return false;
    } else if (control && controlToCompare) {
      return true;
    }
  }

  isBillToValid(billTo, arCode) {
    if (arCode && !billTo) {
      return false;
    } else if (billTo && arCode) {
      return true;
    }
  }

  billToInput(input) {
    if (!input || input.length === 0) {
      this.$v.form.billTo.$model = '';
      this.form.billToName = '';
    }
  }

  billToChange(data) {
    if (!data) {
      return;
    }

    this.$v.form.billTo.$model = data.id;
    this.form.billToName = data.name;
    this.$v.form.sendTo.$model = data.email;
    if (this.checkFormKey('bookingNumber')) {
      this.resetBookingId();
    }
  }

  isDirtyReturn(pullOut, rtn) {
    if (pullOut && rtn) {
      const inputDiffMinutes = lessThenTimeCompare(rtn, pullOut);
      return inputDiffMinutes > 0 ? false : true;
    }
  }

  pulloutScheduleChange() {
    this.pulloutScheduleDate = this.form.pulloutSchedule
      ? moment(this.form.pulloutSchedule, DATE_API_FORMAT).format(
          BS_DATEPICKER_FORMAT
        )
      : '';

    this.deliveryScheduleMinDate = this.pulloutScheduleDate;

    this.deliveryScheduleMinTime = this.form.pulloutSchedule
      ? moment(this.form.pulloutSchedule, DATE_API_FORMAT).format('HH:mm:ss')
      : '';
    this.calculateRFD();
  }

  deliveryScheduleChange() {
    this.returnScheduleMinDate = moment(
      this.form.deliverySchedule,
      DATE_API_FORMAT
    ).format(BS_DATEPICKER_FORMAT);

    this.returnScheduleMinTime = moment(
      this.form.deliverySchedule,
      DATE_API_FORMAT
    ).format('HH:mm');
  }

  showMemo() {
    this.$bvModal.show('BKMemo');
  }

  hasBKNUmber() {
    return this.edit && this.woType === WoType.ImportRegular;
  }

  @Watch('form', { immediate: true, deep: true })
  onFormChanged(formValues: any) {
    this.$emit('update', formValues);
    this.$emit('formValidation', this.$v.form);
  }

  isAvaDatesChecked() {
    if (
      this.woType === WoType.ImportRegular ||
      this.woType === WoType.ImportShuttle
    ) {
      const avaDatesArr = [
        this.form.avaDscDate,
        this.form.avaCusDate,
        this.form.avaOthDate,
        this.form.avaOblDate
      ];

      this.allAvaBoxesChecked = this.isAllAvaBoxesChecked();

      if (
        this.form.avaDscDate &&
        this.form.avaCusDate &&
        this.form.avaOthDate &&
        this.form.avaOblDate
      ) {
        const formatDates = avaDatesArr.map(d =>
          moment(d, DATE_API_FORMAT).format(BS_DATEPICKER_FORMAT)
        );
        const moments = formatDates.map(d => moment(d));
        const maxDate = moment.max(moments).format(DATE_API_FORMAT);

        this.allAvaBoxesChecked ? (this.form.avaDate = maxDate) : '';
      }
    }
  }

  updatePullOut(data) {
    this.$v.form.pulloutLocation.$model = data.id;
    this.form.pulloutLocationName = data.name;
    this.wo.pulloutLocation = this.$v.form.pulloutLocation.$model;
    this.wo.pulloutLocationName = this.form.pulloutLocationName;
    WorkOrderService.updatePullOutSchedule(data, this.wo.schedules);
  }

  updateDelivery(data) {
    this.$v.form.deliveryLocation.$model = data.id;
    this.form.deliveryLocationName = data.name;
    this.wo.deliveryLocation = this.$v.form.deliveryLocation.$model;
    this.wo.deliveryLocationName = this.form.deliveryLocationName;
    WorkOrderService.updateDeliverySchedule(
      data,
      this.$v.form.deliveryLocation.$model,
      this.wo.schedules,
      !!this.wo.returnLocation
    );
  }

  updateReturn(data) {
    this.form.returnLocationName = data.name;
    this.form.returnLocation = data.id;
    this.wo.returnLocationName = this.form.returnLocationName;
    this.wo.returnLocation = this.form.returnLocation;
    WorkOrderService.updateReturnSchedule(
      data,
      this.wo.schedules,
      !!this.form.returnLocation
    );
  }

  isCheckedAvailability(box, picker) {
    if (!this.form[picker]) {
      this.form[picker] = null;
    }

    if (!box) {
      this.form[picker] = null;
      this.form.avaDate = null;
    }

    const today = moment()
      .startOf('day')
      .format(DATE_API_FORMAT);
    if (box && !this.form[picker]) {
      Object.keys(this.form).forEach(key => {
        if (key === picker) {
          this.form[key] = today;
        }
      });
    }
    this.isAvaDatesChecked();
  }

  isAllAvaBoxesChecked() {
    const avaBoxes = [
      this.form.avaDsc,
      this.form.avaObl,
      this.form.avaCus,
      this.form.avaOth
    ];

    return avaBoxes.every(checkbox => (checkbox === true ? true : false));
  }

  destroyed() {
    this.subscriptions.forEach(unsubscribe => unsubscribe());
    this.subscriptions = [];
  }

  mounted() {
    this.subscriptions.push(
      this.fieldChangeEventBus.subscribeToFieldChange(event => {
        switch (event.fieldKey) {
          case 'schedules.scheduleIn':
            this.onScheduleInChange(event);
            break;
        }
      })
    );
  }

  updateDeliveryValidation(isInvalid: boolean) {
    this.scheduleIsValidStates.set(
      LocationType.DELIVERY,
      !isInvalid || !this.form.deliverySchedule
    );
  }

  updateReturnValidation(isInvalid: boolean) {
    this.scheduleIsValidStates.set(
      LocationType.RETURN,
      !isInvalid || !this.form.returnSchedule
    );
  }

  isValidSchedules(): boolean {
    return [...this.scheduleIsValidStates.values()].every(val => val);
  }

  private onScheduleInChange(event: FildChangeEvent) {
    if (
      [
        WoType.ImportShuttle,
        WoType.ImportRegular,
        WoType.ExportShuttle,
        WoType.ExportRegular
      ].indexOf(this.woTypeModel) === -1 ||
      ([WoType.ExportShuttle, WoType.ExportRegular].indexOf(
        this.woTypeModel
      ) !== -1 &&
        this.form.streetTurn?.length) ||
      event.contextData?.locationType !== 'PULLOUT'
    ) {
      return;
    }

    this.calculateRFD();
  }

  private calculateRFD(): void {
    if (this.form.freeDays && this.form.freeDaysType && this.edit) {
      const scheduleInOfPullout = this.getScheduleInOfPullout();

      if (!this.form.streetTurn?.length && !scheduleInOfPullout) {
        return;
      }

      const firstDate: Date = convertStrignDateToDate(
        [WoType.ExportShuttle, WoType.ExportRegular].indexOf(
          this.woTypeModel
        ) !== -1 && this.form.streetTurn?.length
          ? this.form.streetTurn
          : scheduleInOfPullout,
        BS_DATEPICKER_FORMAT
      );

      const freeDaysNumber = parseInt(this.form.freeDays);
      const date =
        this.form.freeDaysType === 'Calendar'
          ? this.calculateCalendarRFD(firstDate, freeDaysNumber)
          : this.calculateWorkingRFD(firstDate, freeDaysNumber);
      this.form.returnFreeDay = convertDateToFormat(date, DATE_API_FORMAT);
    } else {
      this.form.returnFreeDay = '';
    }
  }

  private calculateCalendarRFD(
    scheduleInOfPullout: Date,
    freeDays: number
  ): Date {
    return addDaysToDate(scheduleInOfPullout, freeDays);
  }

  private calculateWorkingRFD(
    scheduleInOfPullout: Date,
    freeDays: number
  ): Date {
    const dateRDF = new Date(scheduleInOfPullout.getTime());

    while (freeDays) {
      dateRDF.setDate(dateRDF.getDate() + 1);

      if (!isWeekend(dateRDF) && !isHoliday(dateRDF)) {
        freeDays--;
      }
    }

    return dateRDF;
  }

  private getScheduleInOfPullout(): string {
    return (
      this.pulloutScheduleDate ||
      this.form.schedules?.find(schedule => 'PULLOUT' === schedule.locationType)
        ?.scheduleIn
    );
  }

  isIncorrectFormatDatepicker() {
    const datePickersRefs = [
      (this.$refs as any).pulloutSchedule,
      (this.$refs as any).deliverySchedule,
      (this.$refs as any).returnSchedule
    ];
    let incorrectDateModelFormat = false;

    datePickersRefs.forEach(element => {
      if (element.$refs.tmsDateTimePicker.incorrectDateModelFormat) {
        incorrectDateModelFormat = true;
      }
    });

    return incorrectDateModelFormat;
  }

  isPoolDisabled(chassisNumber) {
    if (this.selectValues.chassisPool) {
      const poolValue = this.selectValues.chassisPool.find(
        item => item.value === 'POOL'
      );
      const isValidChaissNumber =
        chassisNumber.length > 0 && this.validateState('chassisNumber');

      if (!isValidChaissNumber) {
        poolValue.disabled = false;
        this.$v.form.chassisPool.$touch();
      } else {
        poolValue.disabled = true;
      }

      chassisNumber === '' ? (this.form.chassisPool = '') : '';

      if (isValidChaissNumber && chassisNumber.startsWith('NGLT')) {
        this.form.chassisPool = 'NGL';
      }
    }
  }

  private resetBookingId() {
    if (this.woType !== WoType.ImportRegular) {
      return;
    }

    // this.form.bookingNumber = this.initialBookingId
    //   ? this.initialBookingId
    //   : '';

    this.form.bookingNumber = '';
    this.onFormChanged(this.form);
    this.$forceUpdate();
  }
}
