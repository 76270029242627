import { DivisionModule } from '@/store';
import { ToastHelper } from '@/utils/toast.util';
import { BaseClass } from '../base/base.service';

export class EmailOrderProvider extends BaseClass {
  constructor() {
    super('emails');
  }

  async sendEmail(data) {
    try {
      const res = await this.post(`/send-email?divisionName=${DivisionModule.division}`, data);
      ToastHelper.show(
        'Email',
        'Mail sent successfully',
        5000,
        'success'
      );
      return res.data;
    } catch (err) {
      ToastHelper.show(
        'Email',
        err.message,
        5000,
        'danger'
      );
    }
  }
}

export const EmailOrderService = new EmailOrderProvider();
